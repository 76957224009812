<template>
  <SlCombobox
    :value="value"
    :options="options"
    placeholder=" "
    :internal-search="false"
    open-direction="auto-height"
    append-to-body-option-selector="approval-combobox"
    size="sm"
    :disabled="disabled"
    return-object
    @input="handleInput"
    @open="handleOpen"
    @close="handleClose"
  />
</template>

<script>

export default {
  name: 'ApprovalCombobox',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: Boolean
  },
  data() {
    return {
      localValue: ''
    };
  },
  methods: {
    getParsedValue() {
      return isNaN(+this.localValue) ? this.localValue : +this.localValue;
    },
    handleInput(value) {
      this.localValue = value;
    },
    handleOpen() {
      this.localValue = this.value;
    },
    handleClose() {
      // is inherited option selected
      if (this.localValue.value) {
        return this.$emit('input', this.localValue);
      }

      if (this.localValue === this.value) {
        return;
      }

      this.$emit('input', this.getParsedValue());
    }
  }
};
</script>

<style lang="scss" scoped>
.approval-combobox {
  max-width: 180px;
}
</style>