<template>
  <SlContextMenu
    id="demand-table-context-menu"
    ref="contextMenu"
    :options="availableOptions"
    @option-click="handleOptionClick"
  >
    <template #[contextMenuKeys.ADD_NOTE]="{ option, handler }">
      <SlContextMenuOption
        :handler="handler"
        :disabled="option.disabled"
      >
        {{ noteLabel }}
      </SlContextMenuOption>
    </template>
  </SlContextMenu>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import { fileSaver } from '@/mixins/webAPI';
import {
  demandTableContextMenuOptions,
  demandTableContextMenuActionsMap,
  contextMenuKeys,
  fgsActionTypes
} from '@/config/shared/contextMenu.config';
import { demandTableContextMenuFgs } from '@/config/shared/fgs.config';
import modalsId from '@/config/shared/modalsId.config';
import { copyToClipboard, readClipboardText } from '@/helpers/utils/clipboard';

export default {
  name: 'DemandTableContextMenu',
  mixins: [modal, fileSaver],
  data() {
    return {
      contextMenuKeys,
      fgsActionTypes,
      demandTableContextMenuFgs,
      options: demandTableContextMenuOptions(this),
      context: {}
    };
  },
  computed: {
    ...mapState({
      activeNode: (state) => state.demand.tree.active_node
    }),
    ...mapGetters({
      getActionFgs: 'demand/table/getActionFgs'
    }),
    availableOptions() {
      return this.options.reduce((acc, option) => {
        if (!option.fgs) {
          acc.push(option);

          return acc;
        }

        const visible = this.getActionFgs(option.fgs, this.context[fgsActionTypes.VISIBLE]);

        if (!visible) {
          return acc;
        }

        acc.push({
          ...option,
          disabled: !this.getActionFgs(option.fgs, this.context[fgsActionTypes.ENABLED])
        });

        return acc;
      }, []);
    },
    noteLabel() {
      if (this.context?.note) {
        return this.$t('Main.EditNote');
      }

      return this.$t('Main.AddNote');
    }
  },
  methods: {
    ...mapActions({
      updateTableOverride: 'demand/table/updateTableOverride',
      applyCellToFinalForecast: 'demand/table/applyCellToFinalForecast',
      applyRowToFinalForecast: 'demand/table/applyRowToFinalForecast',
      copyCellFromStatisticalForecast: 'demand/table/copyCellFromStatisticalForecast',
      copyRowFromStatisticalForecast: 'demand/table/copyRowFromStatisticalForecast',
      propagateCell: 'demand/table/propagateCell',
      exportNodeToXlsx: 'demand/exportNodeToXlsx'
    }),
    show({ event, context }) {
      this.context = context;

      this.$refs.contextMenu.show({ event, context });
    },
    handleOptionClick({ key, context }) {
      this[demandTableContextMenuActionsMap[key]]?.(context);
    },
    copyCell({ edit, val }) {
      copyToClipboard(edit ?? val);
    },
    updateCell(value) {
      const { name, index, column } = this.context;

      this.updateTableOverride({
        class: this.context.class,
        name,
        index,
        column,
        value
      });
    },
    async pasteCell() {
      const value = await readClipboardText();

      if (!value) {
        return;
      }

      this.updateCell(value);
    },
    cutCell(context) {
      this.copyCell(context);
      this.deleteCell();
    },
    deleteCell() {
      this.updateCell(null);
    },
    editNote({ column: col, class: row, index, note }) {
      this.showModal(modalsId.ADD_NOTE, {
        ...(note || {}),
        metadata: {
          ...this.activeNode.uniId,
          col,
          row,
          index
        }
      });
    },
    handleExportNodeToXlsx() {
      this.saveFile(this.exportNodeToXlsx.bind(this, false));
    }
  }
};
</script>