<template>
  <div class="settings-widget">
    <SeasonPatternModal />

    <div class="settings-widget__toolbar">
      <SlTabList
        v-model="tabModel"
        :tabs="settingTabs"
        horizontal
      />
    </div>
    <div class="settings-widget__content">
      <SlTabContent
        v-for="tab in settingTabs"
        :key="tab.value"
        :value="tab.value"
        :tab-value="tabModel"
      >
        <SlAlertLine
          v-if="isAlertVisible"
          :accent="settingsWarning"
          type="warn"
        />
        <component :is="tab.component" />
      </SlTabContent>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SeasonPatternModal from '@/components/Modals/SeasonPattern/Index.vue';
import ForecastTab from '@/components/Demand/Settings/Tabs/ForecastTab.vue';
import ModelTab from '@/components/Demand/Settings/Tabs/ModelTab.vue';
import InventoryTab from '@/components/Demand/Settings/Tabs/InventoryTab.vue';
import KPITab from '@/components/Demand/Settings/Tabs/KPITab.vue';
import { access } from '@/mixins/access';
import { modal } from '@/mixins/modal';
import { settingTabs, settingTabKeys } from '@/config/demand/settings.config';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'DemandSettingsWidget',
  components: {
    SeasonPatternModal,
    ForecastTab,
    ModelTab,
    InventoryTab,
    KPITab
  },
  mixins: [access, modal],
  data() {
    return {
      settingTabs,
      tabModel: null,
      actionByTab: {
        [settingTabKeys.MODAL]: this.fetchModelSettings,
        [settingTabKeys.INVENTORY]: this.fetchInventorySettings,
        [settingTabKeys.KPI]: this.fetchKPISettings
      }
    };
  },
  computed: {
    ...mapState({
      settingsWarning: (state) => state.demand.settings.forecast?.settingsWarning
    }),
    ...mapGetters({
      activeNodeId: 'demand/tree/activeNodeId'
    }),
    isAlertVisible() {
      return this.tabModel === settingTabKeys.FORECASTING && this.settingsWarning;
    }
  },
  watch: {
    activeNodeId() {
      this.updateTab(this.tabModel);
    }
  },
  methods: {
    ...mapActions({
      fetchModelSettings: 'demand/settings/fetchModelSettings',
      fetchInventorySettings: 'demand/settings/fetchInventorySettings',
      fetchKPISettings: 'demand/settings/fetchKPISettings'
    }),
    getTooltip,
    updateTab(tab) {
      this.actionByTab[tab]?.();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/demand/settings/settings-widget';
</style>