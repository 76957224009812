<template>
  <SlContextMenu
    id="tree-context-menu"
    ref="contextMenu"
    :options="availableOptions"
    @option-click="handleOptionClick"
  >
    <template #[contextMenuKeys.CLEAR_SETTINGS]="{ handler, option }">
      <SlDropdown
        placement="right-start"
        :distance="0"
        :overflow-padding="5"
        :triggers="['hover']"
        :popper-triggers="['hover']"
        content-class="no-overflow"
      >
        <template #target>
          <SlContextMenuOption :option="option">
            {{ option.name }}
            <template #append>
              <icon
                data="@icons/chevron_right.svg"
                class="size-16 fill-off"
              />
            </template>
          </SlContextMenuOption>
        </template>
        <template #content>
          <SlContextMenuOption
            v-for="subOption in option.options"
            :key="subOption.key"
            v-close-popper
            :option="subOption"
            :handler="() => handleClearNodeSettings(subOption)"
            :disabled="subOption.disabled"
          >
            {{ subOption.name }}
          </SlContextMenuOption>
        </template>
      </SlDropdown>
    </template>
    <template #[contextMenuKeys.EXPORT_NODE_TO_XLSX]="{ handler, option }">
      <SlDropdown
        placement="right-start"
        :distance="0"
        :triggers="['hover']"
        :popper-triggers="['hover']"
      >
        <template #target>
          <SlContextMenuOption :option="option">
            {{ option.name }}
            <template #append>
              <icon
                data="@icons/chevron_right.svg"
                class="size-16 fill-off"
              />
            </template>
          </SlContextMenuOption>
        </template>
        <template #content>
          <SlContextMenuOption
            v-for="subOption in option.options"
            :key="subOption.key"
            v-close-popper
            :option="subOption"
            :handler="() => handleExportNode(subOption.visitLeafs)"
          >
            {{ subOption.name }}
          </SlContextMenuOption>
        </template>
      </SlDropdown>
    </template>
  </SlContextMenu>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import { fileSaver } from '@/mixins/webAPI';
import {
  treeContextMenuOptions,
  treeContextMenuActionsMap,
  contextMenuKeys,
  fgsActionTypes
} from '@/config/shared/contextMenu.config';
import { contextMenuFgs, nodeFlags, treeFgs } from '@/config/shared/fgs.config';
import { namespaceByRoute } from '@/config/report';
import { routeNames } from '@/config/router/router.config';
import modalsId from '@/config/shared/modalsId.config';
import { copyToClipboard } from '@/helpers/utils/clipboard';
import { openLink } from '@/helpers/shared/webAPI';
import { getFindInQuery } from '@/helpers/report/inventoryReport';
import { getNodeUrl } from '@/helpers/demand/tree';
import { ampli } from '@/plugins/product/ampli';

export default {
  name: 'TreeContextMenu',
  mixins: [modal, fileSaver],
  data() {
    return {
      contextMenuKeys,
      fgsActionTypes,
      contextMenuFgs,
      options: treeContextMenuOptions(this),
      context: {}
    };
  },
  inject: [
    'copyItem',
    'toggleApproveState',
    'toggleCheckedState',
    'toggleNeedsAttentionState',
    'openBOMs',
    'removeFromImport',
    'substituteItem'
  ],
  computed: {
    ...mapState({
      activeNode: state => state.demand.tree.active_node
    }),
    ...mapGetters({
      getActionFgs: 'demand/tree/getActionFgs',
      getNoteByEntity: 'note/getNoteByEntity'
    }),
    availableOptions() {
      return this.filterOptions(this.options);
    },
    editableForSomeChildren() {
      return !!this.getActionFgs(this.context.fgs, treeFgs.EDITABLE_FOR_SOME_CHILDREN);
    }
  },
  methods: {
    ...mapActions({
      clearNodeOverrides: 'demand/clearNodeOverrides',
      exportNodeToXlsx: 'demand/exportNodeToXlsx',
      clearNodeSettings: 'demand/clearNodeSettings',
      fetchNotice: 'note/fetchNotice'
    }),
    show({ event, context }) {
      this.context = context;

      this.$refs.contextMenu.show({ event, context });
    },
    filterOptions(options) {
      return options.reduce((acc, option) => {
        if (option.options) {
          acc.push({
            ...option,
            options: this.filterOptions(option.options)
          });

          return acc;
        }

        if (!option.fgs) {
          acc.push(option);

          return acc;
        }

        const visible = this.getActionFgs(option.fgs, this.context[fgsActionTypes.VISIBLE]);

        if (!visible) {
          return acc;
        }

        acc.push({
          ...option,
          disabled: !this.getActionFgs(option.fgs, this.context[fgsActionTypes.ENABLED])
        });

        return acc;
      }, []);
    },
    handleOptionClick({ key, context }) {
      this[treeContextMenuActionsMap[key]]?.(context);
    },
    copyNodeLink({ nodeId }) {
      copyToClipboard(getNodeUrl(nodeId));
    },
    openNodeInNewTab({ nodeId }) {
      openLink(getNodeUrl(nodeId), true);
    },
    openTableLink(namespace, payload) {
      return this.$store.dispatch(`${namespace}/openTableLink`, payload);
    },
    findInInventory({ uniId }) {
      const { chan, ...entity } = uniId;

      this.openTableLink(namespaceByRoute[routeNames.INVENTORY], {
        search: getFindInQuery(entity),
        route: routeNames.INVENTORY
      });
    },
    findInReports({ uniId }) {
      this.openTableLink(namespaceByRoute[routeNames.INVENTORY], {
        search: getFindInQuery(uniId),
        route: routeNames.REPORTS
      });
    },
    async editNote({ uniId, nodeFlags: flags }) {
      const hasNote = flags & nodeFlags.HAS_NOTE;
      let note = this.getNoteByEntity(uniId);

      if (hasNote && !note) {
        note = await this.fetchNotice(uniId);
      }

      this.showModal(modalsId.ADD_NOTE, {
        ...(note || {}),
        metadata: uniId
      });
    },
    handleClearNodeOverrides({ nodeId }) {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        icon: 'style_warning_double',
        title: this.$t('Web.Modals.ActionConfirm.TitleClearOverrides'),
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonClear'),
        confirmCallback: () => this.clearNodeOverrides(nodeId)
      });
    },
    handleClearNodeSettings({ key, title }) {
      const payload = {
        title,
        type: key,
        nodeId: this.context.nodeId,
        allowForAvailableChildren: this.editableForSomeChildren
      };

      if (this.editableForSomeChildren) {
        return this.showModal(modalsId.SL_CONFIRM_MODAL, {
          title: this.$t('Web.Tree.TitlePartiallyModify'),
          text: this.$t('Web.Tree.TextPartiallyModify', { 1: this.context.text }),
          icon: 'style_warning',
          confirmText: this.$t('Web.Tree.BtnProceed'),
          confirmCallback: () => this.clearNodeSettingsByKey(payload)
        });
      }

      this.clearNodeSettingsByKey(payload);
    },
    clearNodeSettingsByKey({ title, ...payload }) {
      this.clearNodeSettings(payload);

      ampli.forecastingParametersOverridden({
        forecastingParameterName: title
      });
    },
    handleExportNode(visitLeafs = false) {
      this.saveFile(this.exportNodeToXlsx.bind(this, visitLeafs));
    }
  }
};
</script>