<template>
  <div class="model-tab">
    <SaveAsPatternModal />
    <SlOverlay :show="initializing" />

    <div
      v-if="isTabAvailable"
      class="model-tab__content"
    >
      <template v-for="model in modelsConfig">
        <div
          v-if="model.isAvailable"
          :key="model.type"
          class="model-tab__table"
        >
          <div
            v-if="model.title"
            class="model-tab__title"
          >
            {{ model.title }}

            <SlButton
              v-if="model.isSeasonal"
              variant="secondary"
              color="grey"
              :disabled="!$sl_tabEditable"
              @click="handleSaveAsPattern"
            >
              {{ $t('Main.Ui.btMakePattern') }}
            </SlButton>
          </div>
          <SlTable
            :id="model.type"
            header-unique-key="key"
            header-name-key="name"
            unique-key="id"
            :rows="model.table.body"
            :headers="model.table.header"
            :max-height="800"
            :col-resize="false"
            :col-initial-width="130"
            :initial-col-sizes="['calc(100% - 130px)', '130px']"
          >
            <template #coefficient="{ row, cellValue }">
              <SlTableInput
                v-if="cellValue"
                :value="getInputValue(cellValue)"
                :text-value="getTextValue(cellValue)"
                :foreground="cellValue.foreground"
                :disabled="!cellValue.editable"
                :input-regexp="regExp.negativeFloatInput"
                :submit-regexp="regExp.negativeFloatSubmit"
                @submit="(val) => handleUpdateModel({
                  cellValue,
                  type: model.type,
                  rowId: row.id,
                  val
                })"
              />
            </template>
          </SlTable>
        </div>
      </template>
    </div>
    <NotAvailableLevel v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SaveAsPatternModal from '@/components/Modals/SeasonPattern/SaveAsPatternModal.vue';
import NotAvailableLevel from '@/components/Demand/Settings/NotAvailableLevel.vue';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import modalsId from '@/config/shared/modalsId.config';
import { modelCoefficientTypes } from '@/config/demand/settings.config';
import regExp from '@/helpers/utils/regExp';

export default {
  name: 'ModelTab',
  components: {
    NotAvailableLevel,
    SaveAsPatternModal
  },
  mixins: [modal, access],
  data() {
    return {
      regExp,
      initializing: false
    };
  },
  computed: {
    ...mapState({
      nodeEntity: state => state.demand.tree.active_node.uniId,
      modelTable: state => state.demand.settings.model?.modelCoeffs,
      topDownModelTable: state => state.demand.settings.model?.topDownCoeffs
    }),
    ...mapGetters({
      modelTypeName: 'demand/chart/modelTypeName',
      patternNames: 'demand/seasonPattern/patternModelNames',
      isModelSeasonal: 'demand/settings/isModelSeasonal'
    }),
    modelsConfig() {
      return [
        {
          type: modelCoefficientTypes.MODEL,
          table: this.modelTable,
          title: this.modelTypeName,
          isAvailable: this.isModelTableAvailable,
          isSeasonal: this.isModelSeasonal
        },
        {
          type: modelCoefficientTypes.TOP_DOWN,
          table: this.topDownModelTable,
          title: this.$t('Main.Ui.TopDownCoeffs'),
          isAvailable: this.isTopDownModelTableAvailable
        }
      ];
    },
    isTabAvailable() {
      return this.isModelTableAvailable || this.isTopDownModelTableAvailable;
    },
    isModelTableAvailable() {
      return this.modelTable?.body?.length;
    },
    isTopDownModelTableAvailable() {
      return this.topDownModelTable?.body?.length;
    }
  },
  beforeMount() {
    this.fetchSettings();
  },
  methods: {
    ...mapActions({
      fetchModelSettings: 'demand/settings/fetchModelSettings',
      updateModelSettings: 'demand/settings/updateModelSettings',
      saveAsPattern: 'demand/seasonPattern/saveAsPattern'
    }),
    getInputValue({ edit, val }) {
      return edit ?? val;
    },
    getTextValue({ val }) {
      return val === undefined ? this.$t('Common.Na') : val;
    },
    async fetchSettings() {
      try {
        this.initializing = true;

        await this.fetchModelSettings();
      } finally {
        this.initializing = false;
      }
    },
    async handleUpdateModel({ cellValue, ...payload }) {
      const originalValue = cellValue.val;
      const originalForeground = cellValue.foreground;

      this.$set(cellValue, 'val', payload.val);
      this.$set(cellValue, 'foreground', 'over');

      try {
        await this.updateModelSettings(payload);
      } catch (e) {
        this.$notify({
          type: 'error',
          title: this.$t('Web.Report.ApplyOverridesError'),
          text: e?.message
        });

        this.$set(cellValue, 'val', originalValue);
        this.$set(cellValue, 'foreground', originalForeground);
      }
    },
    handleSaveAsPattern() {
      if (!this.$sl_tabEditable) {
        return;
      }

      this.showModal(modalsId.SAVE_AS_PATTERN, {
        patternNames: this.patternNames,
        submitCallback: this.saveAsPatternCallback
      });
    },
    saveAsPatternCallback(payload) {
      this.saveAsPattern({
        ...payload,
        item: this.nodeEntity.item,
        location: this.nodeEntity.loc,
        channel: this.nodeEntity.chan
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/settings/tabs/model-tab";
</style>
