<template>
  <div class="toolbar-wrapper">
    <DemandTreeSearch class="toolbar-wrapper__search" />
    <div class="toolbar-wrapper__content">
      <div class="toolbar-wrapper__actions">
        <SlButton
          v-tooltip="getTooltip($t('Web.Demand.Approve'))"
          :disabled="!$sl_hasAccess($sl_features.approveForecast) || !this.$sl_tabEditable"
          variant="tertiary"
          color="grey"
          icon
          @click="toggleApproveState"
        >
          <icon
            data="@tree/approve.svg"
            class="size-16 grey-60 fill-off"
          />
        </SlButton>
        <SlButton
          v-tooltip="getTooltip($t('Main.Ui.acNeedsAttention'))"
          :disabled="!this.$sl_tabEditable"
          variant="tertiary"
          color="grey"
          icon
          @click="toggleNeedsAttentionState"
        >
          <icon
            data="@tree/needs-attention.svg"
            class="size-16 grey-60 fill-off"
          />
        </SlButton>
        <SlButton
          v-tooltip="getTooltip($t('Main.Ui.acMarkChecked'))"
          :disabled="!this.$sl_tabEditable"
          variant="tertiary"
          color="grey"
          icon
          @click="toggleCheckedState"
        >
          <icon
            data="@tree/checked.svg"
            class="size-16 grey-60 fill-off"
          />
        </SlButton>
      </div>
      <div
        v-if="isBreadcrumbsVisible"
        class="toolbar-wrapper__breadcrumbs"
      >
        <SlBreadcrumb
          v-for="(item, index) in breadcrumbs"
          :key="item.id"
          v-tooltip="index && getTooltip(item.text)"
          :index="index"
          size="sm"
          :active="checkIsBreadcrumbActive(index)"
          @click="handleBreadcrumbClick(item.id, index)"
        >
          {{ item.text }}
        </SlBreadcrumb>
      </div>
    </div>
    <div class="toolbar-wrapper__append">
      <SlButton
        variant="secondary"
        color="grey"
        :disabled="!$sl_hasAccess($sl_features.manageForecastVersions) || !this.$sl_tabEditable"
        @click="openCustomRows"
      >
        <template #prepend>
          <icon
            data="@icons/forecast.svg"
            class="size-16 fill-off"
          />
        </template>
        {{ $t('Web.CustomFields.CustomFields') }}
      </SlButton>
      <DemandOptionsDropdown />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DemandOptionsDropdown from '@/components/Demand/Toolbar/DemandOptionsDropdown.vue';
import DemandTreeSearch from '@/components/Demand/Toolbar/DemandTreeSearch.vue';
import { access } from '@/mixins/access';
import { routeNames } from '@/config/router/router.config';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'DemandToolbar',
  components: {
    DemandTreeSearch,
    DemandOptionsDropdown
  },
  mixins: [access],
  computed: {
    ...mapState({
      breadcrumbs: (state) => state.demand.tree.active_node_path || []
    }),
    isBreadcrumbsVisible() {
      return this.breadcrumbs.length > 1;
    }
  },
  methods: {
    ...mapActions({
      searchTreeNodes: 'demand/tree/searchTreeNodes',
      fetchFoundNodeTree: 'demand/tree/fetchFoundNodeTree',
      toggleApproveState: 'demand/tree/toggleApproveState',
      toggleNeedsAttentionState: 'demand/tree/toggleNeedsAttentionState',
      toggleCheckedState: 'demand/tree/toggleCheckedState',
      setActiveNodeByNodeId: 'demand/tree/setActiveNodeByNodeId'
    }),
    getTooltip,
    openCustomRows() {
      this.$router.push({
        name: routeNames.CUSTOM_ROWS
      });
    },
    checkIsBreadcrumbActive(index) {
      return index === this.breadcrumbs.length - 1;
    },
    handleBreadcrumbClick(id, index) {
      if (this.checkIsBreadcrumbActive(index)) {
        return;
      }

      this.setActiveNodeByNodeId(id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/toolbar";
</style>
