<template>
  <div
    :class="['demand-table__header', {
      'demand-table__header--scrolled': scrolled
    }]"
  >
    <div class="demand-table__cell demand-table__cell--header" />
    <div
      v-for="(header, headerIndex) of headers"
      :key="`${header.class}_${headerIndex}`"
      class="demand-table__cell demand-table__cell--header"
      :data-test-id="`demand-tbl-header-${header.class.toLowerCase()}`"
    >
      {{ header.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DemandTableHeader',
  props: {
    headers: {
      type: Array,
      required: true
    },
    scrolled: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/table/demand-table-cell";
@import "@/style/components/demand/table/demand-table-header";
</style>
