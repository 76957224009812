<template>
  <div class="settings-tab">
    <SlOverlay :show="initializing" />

    <div class="settings-tab__table">
      <SlTable
        v-if="kpiTable"
        id="kpi"
        header-unique-key="key"
        header-name-key="name"
        unique-key="id"
        :rows="kpiTable.body"
        :headers="kpiTable.header"
        :max-height="1000"
        :col-resize="false"
        :col-initial-width="130"
        :initial-col-sizes="['calc(100% - 130px)', '130px']"
      >
        <template #value="{ cellValue }">
          <SlTableInput
            v-if="cellValue"
            :text-value="getTextValue(cellValue)"
            :foreground="cellValue.foreground"
            :background="cellValue.background"
            disabled
          />
        </template>
      </SlTable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'KPITab',
  data() {
    return {
      initializing: false
    };
  },
  computed: {
    ...mapState({
      kpiTable: state => state.demand.settings.kpi
    })
  },
  beforeMount() {
    this.fetchSettings();
  },
  methods: {
    ...mapActions({
      fetchKPISettings: 'demand/settings/fetchKPISettings'
    }),
    getTextValue(value) {
      return value?.val || '';
    },
    async fetchSettings() {
      try {
        this.initializing = true;

        await this.fetchKPISettings();
      } finally {
        this.initializing = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/settings/tabs/settings-tab";
</style>
