<template>
  <SlGroupDropdown @toggle="handleDropdownToggle">
    <SlGroupDropdownTransition>
      <template #group>
        <div
          v-if="!isShowHideVisible"
          key="rootContent"
          class="group"
        >
          <SlGroupDropdownOption :title="$t('Web.Report.ViewOptions')">
            <SlDropdownOption
              round
              @select="toggleShowHide"
            >
              <template #prepend>
                <icon
                  data="@icons/table.svg"
                  class="fill-off size-16 color-grey-50"
                />
              </template>
              {{ $t('Web.Demand.ShowRows') }}
              <template #append>
                <span class="body-1 grey-50">
                  {{ $t('Web.Report.ColumnsShown', { 1: rowsVisibility.visibleCount }) }}
                </span>
                <icon
                  data="@icons/chevron_right.svg"
                  class="fill-off size-16 color-grey-60 "
                />
              </template>
            </SlDropdownOption>
          </SlGroupDropdownOption>
          <SlGroupDropdownOption
            :title="$t('Web.Demand.Export')"
            divider
          >
            <SlDropdown
              placement="left-start"
              content-class="w-150"
            >
              <template #target>
                <SlDropdownOption round>
                  <template #prepend>
                    <icon
                      data="@icons/export_table.svg"
                      class="fill-off size-16 color-grey-50"
                    />
                  </template>
                  {{ $t('Main.LocalExportTable') }}
                  <template #append>
                    <icon
                      data="@icons/chevron_right.svg"
                      class="fill-off size-16 color-grey-60 "
                    />
                  </template>
                </SlDropdownOption>
              </template>
              <template #content>
                <SlDropdownOption
                  select-close
                  @select="handleExportNode"
                >
                  {{ $t('Main.Ui.acExportCurrentToXlsx') }}
                </SlDropdownOption>
                <SlDropdownOption
                  select-close
                  @select="handleExportNode(true)"
                >
                  {{ $t('Main.Ui.acExportAllToXlsx') }}
                </SlDropdownOption>
              </template>
            </SlDropdown>
          </SlGroupDropdownOption>
        </div>
      </template>
      <template #category>
        <ShowHideCategory
          v-if="isShowHideVisible"
          key="ShowHideCategory"
          :title="$t('Web.Demand.ShowRows')"
          :options="rowsVisibility.rows"
          unique-key="class"
          class="category"
          @back="toggleShowHide"
          @toggle-visibility="updateRowVisibility"
        />
      </template>
    </SlGroupDropdownTransition>
  </SlGroupDropdown>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ShowHideCategory from '@/components/Shared/OptionsMenu/ShowHideCategory.vue';
import { fileSaver } from '@/mixins/webAPI';

export default {
  name: 'DemandOptionsDropdown',
  components: {
    ShowHideCategory
  },
  mixins: [fileSaver],
  data() {
    return {
      isShowHideVisible: false
    };
  },
  computed: {
    ...mapState({
      rowsVisibility: state => state.demand.table.rows_visibility || {}
    })
  },
  methods: {
    ...mapActions({
      exportNodeToXlsx: 'demand/exportNodeToXlsx',
      updateRowVisibility: 'demand/table/updateRowVisibility'
    }),
    toggleShowHide() {
      this.isShowHideVisible = !this.isShowHideVisible;
    },
    handleDropdownToggle() {
      this.isShowHideVisible = false;
    },
    handleExportNode(visitLeafs = false) {
      this.saveFile(this.exportNodeToXlsx.bind(this, visitLeafs));
    }
  }
};
</script>
