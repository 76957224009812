<template>
  <div class="settings-tab">
    <SlOverlay :show="initializing" />

    <div
      v-if="isTabAvailable"
      class="settings-tab__table"
    >
      <SlTable
        v-if="inventoryTable"
        id="inventory"
        header-unique-key="key"
        header-name-key="name"
        unique-key="id"
        :rows="inventoryTable.body"
        :headers="inventoryTable.header"
        :max-height="1000"
        :col-resize="false"
        :col-initial-width="130"
        :initial-col-sizes="['calc(100% - 130px)', '130px']"
      >
        <template #value="{ cellValue }">
          <SlTableInput
            v-if="cellValue"
            :text-value="getTextValue(cellValue)"
            :foreground="cellValue.foreground"
            :background="cellValue.background"
            :editable-bg="false"
            read-only
          />
        </template>
      </SlTable>
    </div>
    <NotAvailableLevel v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import NotAvailableLevel from '@/components/Demand/Settings/NotAvailableLevel.vue';
import { nodeFlags } from '@/config/shared/fgs.config';

export default {
  name: 'InventoryTab',
  components: {
    NotAvailableLevel
  },
  data() {
    return {
      initializing: false
    };
  },
  computed: {
    ...mapState({
      inventoryTable: state => state.demand.settings.inventory
    }),
    ...mapGetters({
      wantInvModel: 'project/wantInvModel',
      nodeFlagByShift: 'demand/table/nodeFlagByShift'
    }),
    isEntry() {
      return !!this.nodeFlagByShift(nodeFlags.IS_ENTRY);
    },
    isTabAvailable() {
      return this.wantInvModel && this.isEntry;
    }
  },
  beforeMount() {
    if (!this.isTabAvailable) {
      return;
    }

    this.fetchSettings();
  },
  methods: {
    ...mapActions({
      fetchInventorySettings: 'demand/settings/fetchInventorySettings'
    }),
    getTextValue(value) {
      return value?.val || '';
    },
    async fetchSettings() {
      try {
        this.initializing = true;

        await this.fetchInventorySettings();
      } finally {
        this.initializing = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/settings/tabs/settings-tab";
</style>
