var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"demand-grid"},[_c('SlResizable',_vm._b({staticClass:"tree-resizable",on:{"resize:end":function (ref) {
    var width = ref.width;

    return _vm.handleResize({
      field: _vm.uiSettingsKeys.DEMAND_TREE_WIDTH,
      size: width
    });
}}},'SlResizable',_vm.treeResizable,false),[_c('TreeWrapper')],1),_c('div',{staticClass:"demand-grid__node"},[_c('SlResizable',_vm._b({staticClass:"table-resizable",on:{"resize:end":function (ref) {
        var height = ref.height;

        return _vm.handleResize({
        field: _vm.uiSettingsKeys.DEMAND_TABLE_HEIGHT,
        size: height
      });
}}},'SlResizable',_vm.tableResizable,false),[_c('DemandTable',{attrs:{"ui-settings":_vm.demandUiSettings}})],1),_c('div',{staticClass:"demand-grid__node-footer"},[_c('DemandChartsWidget'),_c('SlResizable',_vm._b({staticClass:"settings-resizable",on:{"resize:end":function (ref) {
          var width = ref.width;

          return _vm.handleResize({
          field: _vm.uiSettingsKeys.DEMAND_SETTINGS_WIDTH,
          size: width
        });
}}},'SlResizable',_vm.settingsResizable,false),[_c('DemandSettingsWidget')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }