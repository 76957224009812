export const updateChartDatasets = (chart, newDatasets) => {
  const existingDatasets = chart.config.data.datasets;
  const datasetCounts = new Map();

  newDatasets.forEach((newDataset) => {
    const count = datasetCounts.get(newDataset.meaning) || 0;

    const matchingDatasets = existingDatasets.filter(({ meaning }) => meaning === newDataset.meaning);

    if (matchingDatasets[count]) {
      Object.assign(matchingDatasets[count], newDataset);
    } else {
      existingDatasets.push(newDataset);
    }

    datasetCounts.set(newDataset.meaning, count + 1);
  });

  chart.config.data.datasets = existingDatasets.filter(({ meaning }) => {
    const count = datasetCounts.get(meaning) || 0;

    if (count) {
      datasetCounts.set(meaning, count - 1);

      return true;
    }

    return false;
  });
};
