<template>
  <div class="forecast-settings__row">
    <div class="forecast-settings__row-title">
      {{ name }}
    </div>
    <div
      :class="['forecast-settings__row-content', {
        'forecast-settings__row-content--button': button
      }]"
    >
      <template v-if="isSelect">
        <SlSelect
          :value="value"
          :options="options"
          :disabled="isDisabled"
          size="sm"
          @input="handeSettingChange"
        >
          <template #singleLabel>
            <div class="forecast-settings__select-label">
              <icon
                v-if="selectedOption.icon"
                :data="getIcon(selectedOption.icon)"
                class="size-16 fill-off"
              />
              <span>
                {{ selectedOption.label }}
              </span>
            </div>
          </template>
          <template #selectOption="{ option }">
            <div class="forecast-settings__select-option">
              <icon
                v-if="option.icon"
                :data="getIcon(option.icon)"
                class="size-16 fill-off"
              />
              {{ option.label }}
            </div>
          </template>
        </SlSelect>
        <SlButton
          v-if="button"
          variant="secondary"
          color="grey"
          size="lg"
          icon
          @click="button.callback"
        >
          <icon
            :data="getIcon(button.icon)"
            class="size-16 fill-off"
          />
        </SlButton>
      </template>
      <CloneCombobox
        v-if="isCloneCombobox"
        :key="value"
        :value="value"
        :disabled="isDisabled"
        @input="handeSettingChange"
      />
      <ApprovalCombobox
        v-if="isApprovalCombobox"
        :key="value"
        :value="value"
        :options="options"
        :disabled="isDisabled"
        @input="handeSettingChange"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApprovalCombobox from '@/components/Demand/Settings/Forecast/ApprovalCombobox.vue';
import CloneCombobox from '@/components/Demand/Settings/Forecast/CloneCombobox.vue';
import { access } from '@/mixins/access';
import { modal } from '@/mixins/modal';
import { forecastingKeys } from '@/config/demand/settings.config';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'ForecastSettingsRow',
  components: {
    ApprovalCombobox,
    CloneCombobox
  },
  mixins: [access, modal],
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Object],
      default: ''
    },
    rowKey: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => ([])
    },
    button: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      forecastingKeys
    };
  },
  computed: {
    ...mapState({
      activeNode: state => state.demand.tree.active_node
    }),
    ...mapGetters({
      editableForSomeChildren: 'demand/tree/editableForSomeChildren'
    }),
    selectedOption() {
      return this.options.find(option => option.value === this.value) || {};
    },
    isDisabled() {
      return !this.$sl_tabEditable || !this.$sl_hasAccess(this.$sl_features.demandSettings);
    },
    isCloneCombobox() {
      return this.rowKey === forecastingKeys.CLONE;
    },
    isApprovalCombobox() {
      return this.rowKey === forecastingKeys.HS_APPROVAL;
    },
    isSelect() {
      return !this.isCloneCombobox && !this.isApprovalCombobox;
    }
  },
  methods: {
    getIcon(icon) {
      return require(`@tree/${icon}.svg`);
    },
    emitChange(value) {
      this.$emit('change', {
        key: this.rowKey,
        value
      });
    },
    handeSettingChange(value) {
      if (!this.editableForSomeChildren) {
        return this.emitChange(value);
      }

      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Tree.TitlePartiallyModify'),
        text: this.$t('Web.Tree.TextPartiallyModify', { 1: this.activeNode.text }),
        icon: 'style_warning',
        confirmText: this.$t('Web.Tree.BtnProceed'),
        confirmCallback: () => this.emitChange(value)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/demand/settings/forecast-settings-row';
</style>
