<template>
  <div class="demand-grid">
    <SlResizable
      v-bind="treeResizable"
      class="tree-resizable"
      @resize:end="({ width }) => handleResize({
        field: uiSettingsKeys.DEMAND_TREE_WIDTH,
        size: width
      })"
    >
      <TreeWrapper />
    </SlResizable>

    <div class="demand-grid__node">
      <SlResizable
        v-bind="tableResizable"
        class="table-resizable"
        @resize:end="({ height }) => handleResize({
          field: uiSettingsKeys.DEMAND_TABLE_HEIGHT,
          size: height
        })"
      >
        <DemandTable :ui-settings="demandUiSettings" />
      </SlResizable>

      <div class="demand-grid__node-footer">
        <DemandChartsWidget />

        <SlResizable
          v-bind="settingsResizable"
          class="settings-resizable"
          @resize:end="({ width }) => handleResize({
            field: uiSettingsKeys.DEMAND_SETTINGS_WIDTH,
            size: width
          })"
        >
          <DemandSettingsWidget />
        </SlResizable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TreeWrapper from '@/components/Demand/Tree/TreeWrapper.vue';
import DemandTable from '@/components/Demand/DemandTable/Index.vue';
import DemandChartsWidget from '@/components/Demand/Chart/DemandChartsWidget.vue';
import DemandSettingsWidget from '@/components/Demand/Settings/DemandSettingsWidget.vue';
import { uiSettingsKeys, uiTabByRoute } from '@/config/users/uiSettings.config';

export default {
  name: 'DemandGrid',
  components: {
    TreeWrapper,
    DemandTable,
    DemandChartsWidget,
    DemandSettingsWidget
  },
  data() {
    return {
      uiSettingsKeys
    };
  },
  computed: {
    ...mapState({
      uiSettings: (state) => state.user.ui_settings
    }),
    demandUiSettings() {
      return this.uiSettings[uiTabByRoute[this.$sl_routeName]];
    },
    treeResizable() {
      return {
        active: ['r'],
        width: this.demandUiSettings?.[uiSettingsKeys.SPECIFIC]?.[uiSettingsKeys.DEMAND_TREE_WIDTH] || 240
      };
    },
    tableResizable() {
      return {
        active: ['b'],
        height: this.demandUiSettings?.[uiSettingsKeys.SPECIFIC][uiSettingsKeys.DEMAND_TABLE_HEIGHT] || '50%'
      };
    },
    settingsResizable() {
      return {
        active: ['l'],
        disableAttributes: ['l'],
        width: this.demandUiSettings?.[uiSettingsKeys.SPECIFIC][uiSettingsKeys.DEMAND_SETTINGS_WIDTH] || 'auto'
      };
    }
  },
  methods: {
    ...mapActions({
      changeUiSettings: 'user/changeUiSettings'
    }),
    handleResize({ field, size }) {
      this.changeUiSettings({
        key: uiSettingsKeys.SPECIFIC,
        value: {
          [field]: Math.round(size)
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/demand/demand-grid';
</style>