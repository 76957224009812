<template>
  <div class="not-available-level">
    <svg width="118" height="96" viewBox="0 0 118 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_805_114048)">
        <path d="M15.6465 47.9999C15.6465 59.4983 20.2142 70.5257 28.3448 78.6563C36.4754 86.787 47.5029 91.3547 59.0013 91.3547C70.4997 91.3547 81.5272 86.787 89.6578 78.6563C97.7884 70.5257 102.356 59.4983 102.356 47.9999C102.356 36.5014 97.7884 25.474 89.6578 17.3434C81.5272 9.21275 70.4997 4.64502 59.0013 4.64502C47.5029 4.64502 36.4754 9.21275 28.3448 17.3434C20.2142 25.474 15.6465 36.5014 15.6465 47.9999Z" fill="#E5E7EB"/>
        <path d="M37.1816 60.8129C37.1816 61.2269 37.5523 61.6368 38.2723 62.0193C38.9924 62.4017 40.0478 62.7492 41.3783 63.042C42.7088 63.3347 44.2884 63.5669 46.0268 63.7253C47.7651 63.8837 49.6283 63.9652 51.51 63.9652C53.3916 63.9652 55.2548 63.8837 56.9932 63.7253C58.7316 63.5669 60.3111 63.3347 61.6416 63.042C62.9721 62.7492 64.0275 62.4017 64.7476 62.0193C65.4677 61.6368 65.8383 61.2269 65.8383 60.8129C65.8383 59.9769 64.3287 59.1751 61.6416 58.5839C58.9545 57.9928 55.3101 57.6606 51.51 57.6606C47.7099 57.6606 44.0654 57.9928 41.3783 58.5839C38.6912 59.1751 37.1816 59.9769 37.1816 60.8129Z" fill="#D2D6DB"/>
        <path d="M68.276 31.2975V31.2971C68.2761 31.218 68.2605 31.1396 68.2303 31.0665C68.2001 30.9934 68.1557 30.927 68.0998 30.871M68.276 31.2975L68.0998 30.871M68.276 31.2975V49.4463H36.2344V31.2968C36.2344 31.137 36.2978 30.9838 36.4108 30.8708C36.5238 30.7578 36.677 30.6943 36.8368 30.6943H67.6741M68.276 31.2975L67.6741 30.6943M68.0998 30.871C68.0439 30.815 67.9775 30.7706 67.9044 30.7403C67.8314 30.71 67.7532 30.6944 67.6741 30.6943M68.0998 30.871L67.6741 30.6943" fill="#D2D6DB" stroke="#9DA4AE" stroke-width="1.5"/>
        <path d="M70.8717 40.3896H70.8721C71.019 40.3895 71.164 40.422 71.2967 40.4848C71.4294 40.5475 71.5465 40.639 71.6397 40.7525L72.2195 40.2768L71.6397 40.7525C71.7328 40.866 71.7995 40.9988 71.8351 41.1412C71.8707 41.2836 71.8742 41.4322 71.8454 41.5762L72.5808 41.7233L71.8454 41.5762C70.9955 45.8257 69.0812 55.3978 68.3628 58.9926C68.3174 59.2168 68.196 59.4184 68.019 59.5633C67.8417 59.7084 67.6197 59.7877 67.3906 59.7877H37.4008H37.4003C37.1708 59.7879 36.9484 59.7084 36.771 59.5629C36.5935 59.4174 36.4721 59.2148 36.4273 58.9897L36.4272 58.9889L31.9003 36.3531L31.9002 36.3527C31.8714 36.2088 31.8748 36.0603 31.9103 35.9179C31.9458 35.7755 32.0125 35.6428 32.1056 35.5293C32.1986 35.4158 32.3157 35.3244 32.4484 35.2616C32.5811 35.1989 32.726 35.1664 32.8728 35.1665H32.8732L44.1229 35.1665L44.1241 35.1665C44.3528 35.1661 44.5746 35.2452 44.7515 35.3901L45.2229 34.8146L44.7515 35.3901C44.9285 35.535 45.0496 35.7369 45.0943 35.9612L45.0944 35.9618L45.5798 38.3872C45.6928 38.9519 45.998 39.4601 46.4434 39.8251C46.8889 40.1902 47.447 40.3897 48.0229 40.3896C48.0229 40.3896 48.023 40.3896 48.023 40.3896H70.8717Z" fill="white" stroke="#9DA4AE" stroke-width="1.5"/>
        <path d="M76.4447 32.9958C76.3102 32.9637 76.1905 32.8872 76.1049 32.7787C76.0192 32.6702 75.9727 32.5361 75.9727 32.3978C75.9727 32.2596 76.0192 32.1254 76.1049 32.0169C76.1905 31.9085 76.3102 31.832 76.4447 31.7999L82.2668 30.4003C82.3571 30.3786 82.4511 30.3777 82.5418 30.3975C82.6325 30.4173 82.7176 30.4574 82.7906 30.5147C82.8636 30.5721 82.9227 30.6452 82.9635 30.7286C83.0042 30.812 83.0256 30.9036 83.0259 30.9965L83.0345 33.7956C83.0348 33.8888 83.0139 33.9807 82.9735 34.0646C82.9331 34.1485 82.8741 34.2221 82.8011 34.2799C82.728 34.3376 82.6428 34.378 82.5519 34.398C82.4609 34.418 82.3666 34.4171 82.2761 34.3954L76.4447 32.9958Z" fill="white" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M71.6949 28.0748C71.577 28.1459 71.4386 28.1754 71.302 28.1585C71.1653 28.1417 71.0382 28.0795 70.9411 27.982C70.8439 27.8844 70.7823 27.7571 70.766 27.6203C70.7498 27.4836 70.7798 27.3454 70.8514 27.2278L75.2446 20.0547C75.2931 19.9755 75.3588 19.9083 75.4369 19.8582C75.5151 19.808 75.6035 19.7762 75.6957 19.7651C75.7878 19.7541 75.8813 19.764 75.9691 19.7942C76.0569 19.8244 76.1367 19.8741 76.2025 19.9396L78.9902 22.7109C79.0562 22.7766 79.1065 22.8564 79.1371 22.9443C79.1678 23.0322 79.1781 23.126 79.1673 23.2185C79.1565 23.311 79.1249 23.3998 79.0748 23.4783C79.0246 23.5568 78.9574 23.6229 78.878 23.6716L71.6949 28.0748Z" fill="white" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M61.1263 21.3C61.094 21.4343 61.0173 21.5538 60.9088 21.6392C60.8003 21.7247 60.6662 21.7712 60.528 21.7712C60.3899 21.7712 60.2558 21.7247 60.1473 21.6392C60.0388 21.5538 59.9621 21.4343 59.9298 21.3L58.5309 15.4779C58.5091 15.3876 58.508 15.2935 58.5277 15.2028C58.5475 15.112 58.5876 15.0269 58.6449 14.9538C58.7023 14.8808 58.7755 14.8217 58.859 14.7809C58.9425 14.7402 59.0341 14.719 59.127 14.7188L61.9255 14.7095C62.0187 14.7092 62.1108 14.73 62.1947 14.7705C62.2787 14.811 62.3524 14.87 62.4102 14.9431C62.468 15.0162 62.5085 15.1015 62.5285 15.1925C62.5486 15.2836 62.5477 15.378 62.5259 15.4686L61.1263 21.3Z" fill="white" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M56.2065 26.0462C56.2788 26.164 56.3094 26.3027 56.2933 26.44C56.2771 26.5772 56.2152 26.7051 56.1174 26.8028C56.0197 26.9005 55.8919 26.9625 55.7546 26.9786C55.6173 26.9948 55.4786 26.9642 55.3608 26.8918L48.1885 22.4993C48.1093 22.4508 48.0421 22.385 47.9919 22.3068C47.9418 22.2287 47.91 22.1401 47.8989 22.0479C47.8878 21.9557 47.8978 21.8622 47.928 21.7744C47.9582 21.6865 48.0079 21.6067 48.0734 21.5408L50.844 18.753C50.9097 18.687 50.9895 18.6368 51.0774 18.6061C51.1653 18.5755 51.2591 18.5651 51.3516 18.5759C51.4441 18.5867 51.5329 18.6184 51.6114 18.6685C51.6899 18.7186 51.756 18.7859 51.8047 18.8653L56.2065 26.0462Z" fill="white" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M70.1406 66.4982L72.7346 63.9043L77.2739 68.4438L74.68 71.0377L70.1406 66.4982Z" fill="white" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M85.5603 76.4952L85.5615 76.4963C85.7396 76.6736 85.8809 76.8844 85.9773 77.1165L86.6699 76.8287L85.9773 77.1165C86.0738 77.3486 86.1234 77.5974 86.1234 77.8487C86.1234 78.1 86.0738 78.3489 85.9773 78.581L86.6699 78.8687L85.9773 78.581C85.8809 78.813 85.7396 79.0238 85.5615 79.2011L85.4379 79.324C85.4378 79.3241 85.4378 79.3241 85.4377 79.3242C85.0773 79.6818 84.5901 79.8825 84.0824 79.8825C83.575 79.8825 83.0881 79.6821 82.7278 79.3248C82.7275 79.3245 82.7272 79.3243 82.7269 79.324L74.5407 71.1371C74.5406 71.1369 74.5405 71.1368 74.5403 71.1367C74.5145 71.1105 74.5 71.0752 74.5 71.0384C74.5 71.0016 74.5145 70.9663 74.5403 70.9401C74.5405 70.94 74.5406 70.9398 74.5407 70.9397L77.1731 68.3066C77.1997 68.2806 77.2353 68.2661 77.2724 68.2661C77.3096 68.2661 77.3452 68.2807 77.3718 68.3066C77.372 68.3068 77.3722 68.3071 77.3725 68.3073L85.5603 76.4952Z" fill="#D2D6DB" stroke="#9DA4AE" stroke-width="1.5"/>
        <path d="M50.6261 63.9514C51.569 65.4922 52.8061 66.8323 54.2669 67.895C55.7276 68.9577 57.3834 69.7223 59.1397 70.1451C60.8959 70.5679 62.7183 70.6407 64.5026 70.3592C66.287 70.0777 67.9985 69.4476 69.5393 68.5047C71.0801 67.5618 72.4202 66.3246 73.4829 64.8639C74.5456 63.4031 75.3102 61.7473 75.733 59.9911C76.1558 58.2348 76.2285 56.4125 75.9471 54.6281C75.6656 52.8437 75.0354 51.1322 74.0925 49.5914C73.1496 48.0506 71.9125 46.7106 70.4517 45.6478C68.991 44.5851 67.3352 43.8205 65.5789 43.3977C63.8227 42.9749 62.0003 42.9022 60.2159 43.1836C58.4316 43.4651 56.7201 44.0953 55.1793 45.0382C53.6385 45.9811 52.2984 47.2182 51.2357 48.679C50.173 50.1397 49.4084 51.7955 48.9856 53.5518C48.5628 55.308 48.4901 57.1304 48.7715 58.9147C49.053 60.6991 49.6832 62.4106 50.6261 63.9514Z" fill="white" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M69.6089 49.5208C71.0442 50.9551 72.0218 52.7829 72.4181 54.773C72.8144 56.763 72.6117 58.8259 71.8355 60.7007C71.0593 62.5755 69.7445 64.1779 68.0574 65.3054C66.3703 66.4328 64.3868 67.0346 62.3577 67.0346C60.3285 67.0346 58.345 66.4328 56.6579 65.3054C54.9709 64.1779 53.6561 62.5755 52.8798 60.7007C52.1036 58.8259 51.9009 56.763 52.2972 54.773C52.6935 52.7829 53.6712 50.9551 55.1064 49.5208C57.03 47.5984 59.6382 46.5186 62.3577 46.5186C65.0771 46.5186 67.6853 47.5984 69.6089 49.5208Z" fill="#D2D6DB" stroke="#9DA4AE" stroke-width="1.5"/>
        <path d="M57.0312 60.8182C57.6171 59.8039 58.4618 58.9632 59.479 58.3821C60.4961 57.8011 61.6493 57.5005 62.8207 57.511C63.9921 57.5216 65.1396 57.8429 66.1462 58.4421C67.1527 59.0414 67.9821 59.8971 68.5497 60.9219" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M58.373 51.8281L60.9199 54.375" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M58.373 54.375L60.9199 51.8281" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M64.084 51.8281L66.6308 54.375" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M64.084 54.375L66.6308 51.8281" stroke="#9DA4AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_805_114048">
          <rect width="86.7097" height="86.7097" fill="white" transform="translate(15.6465 4.64502)"/>
        </clipPath>
      </defs>
    </svg>
    <div class="body-1-md grey-100">
      {{ $t('Main.Ui.lbNotAvModel') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotAvailableLevel'
};
</script>

<style lang="scss" scoped>
.not-available-level {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 100%;
  width: 100%;
}
</style>