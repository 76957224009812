import { getRelativePosition } from 'chart.js/helpers';

let contextHandler = null;

export const contextMenuPlugin = {
  id: 'contextMenu',
  afterInit(chart, args, options) {
    contextHandler = (event) => (() => {
      const { context, ref } = options;
      const instance = context.$refs[ref];
      const canvasPosition = getRelativePosition(event, chart);

      // Substitute the appropriate scale IDs
      const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);
      const dataY = chart.scales.y.getValueForPixel(canvasPosition.y);

      instance.show({
        event,
        context: {
          chart,
          dataX,
          dataY
        }
      });
    })();

    chart.canvas.addEventListener('contextmenu', contextHandler);
  },
  beforeDestroy(chart) {
    chart.canvas?.removeEventListener('contextmenu', contextHandler);
  }
};