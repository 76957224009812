<template>
  <div
    id="demand-table"
    class="demand-table"
  >
    <DemandTableContextMenu ref="contextMenu" />

    <DynamicScroller
      ref="scrollContainer"
      :items="columns"
      key-field="date"
      direction="horizontal"
      :min-item-size="columnWidth"
      class="demand-table__scroller"
      @scroll.native.passive="updateScroll"
    >
      <template #before>
        <TableHeader
          :headers="headers"
          :scrolled="hasHorizontalScrollbar && !scrolledToLeft"
        />
      </template>
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :data-index="index"
          emit-resize
        >
          <DemandTableColumn
            :column="item"
            :column-index="+index"
            :custom-rows-count="customRowsCount"
            :disabled="isColumnDisabled"
            :data-resize="index"
            :data-test-id="`demand-tbl-col-${index}`"
            :style="getColumnStyles(index)"
            @table-ready="onTableReady"
            @contextmenu="handleContextMenu"
          />
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import DemandTableColumn from '@/components/Demand/DemandTable/DemandTableColumn.vue';
import TableHeader from '@/components/Demand/DemandTable/DemandTableHeader.vue';
import DemandTableContextMenu from '@/components/Demand/DemandTable/DemandTableContextMenu.vue';
import { modal } from '@/mixins/modal';
import { colResize } from '@/mixins/colResize';
import { access } from '@/mixins/access';
import { scroll } from '@/mixins/scroll';
import { uiSettingsKeys } from '@/config/users/uiSettings.config';

export default {
  name: 'DemandTable',
  components: {
    DemandTableContextMenu,
    DemandTableColumn,
    TableHeader
  },
  mixins: [modal, colResize, access, scroll],
  props: {
    uiSettings: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      cellHeight: '30px',
      minColumnWidth: 40,
      defaultColumnWidth: 110
    };
  },
  computed: {
    ...mapState({
      table: (state) => state.demand.table.table || {},
      serverSideColSizes: (state) => state.demand.table.column_sizes
    }),
    ...mapGetters({
      pKnown: 'project/pKnown'
    }),
    columns() {
      return this.table.body || [];
    },
    headers() {
      return this.table.header || [];
    },
    customRowsCount() {
      return this.table.metadata?.customRowsCount || 0;
    },
    columnWidth() {
      return this.uiSettings?.[uiSettingsKeys.COLUMNS_WIDTH]?.default ?? this.defaultColumnWidth;
    },
    isColumnDisabled() {
      return !(this.$sl_tabEditable && this.$sl_hasAccess(this.$sl_features.demandNode));
    }
  },
  methods: {
    ...mapActions({
      changeUiSettings: 'user/changeUiSettings'
    }),
    onTableReady() {
      this.scrollToEditableElement(this.initColumnResizer);
    },
    initColumnResizer() {
      this.initResize({
        tableId: 'demand-table',
        colSelector: '.demand-table__column',
        minWidth: this.minColumnWidth,
        resizerHeight: this.cellHeight,
        activeResizerHeight: this.cellHeight,
        initialColSizes: this.serverSideColSizes || undefined,
        updateSizeCallback: (data) => this.handleColResize(data)
      });
    },
    getColumnStyles(index) {
      return {
        width: (this.colSizes[index] || this.columnWidth) + 'px'
      };
    },
    handleColResize(data) {
      const clazz = this.columns[+data.index].date;

      this.changeUiSettings({
        key: uiSettingsKeys.COLUMNS_WIDTH,
        value: {
          clazz,
          width: data.width
        }
      });
    },
    scrollToEditableElement(callback) {
      const { scrollToItem } = this.$refs.scrollContainer;

      scrollToItem && scrollToItem(this.pKnown - 3);
      setTimeout(callback, 200);
    },
    handleContextMenu(context) {
      this.$refs.contextMenu.show(context);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/table/table";
</style>
