<template>
  <div
    :class="['tree-wrapper', {
      'loading': isFetching
    }]"
  >
    <Tree />
    <TreeFilter />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TreeFilter from '@/components/Demand/Tree/TreeFilter.vue';
import Tree from '@/components/Demand/Tree/Tree.vue';

export default {
  name: 'TreeWrapper',
  components: {
    TreeFilter,
    Tree
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.demand.tree.is_fetching
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/tree/tree-wrapper.scss";
</style>
