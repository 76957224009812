import vueI18n from '@/plugins/vue-i18n';

export const settingTabKeys = {
  FORECASTING: 'forecasting',
  MODAL: 'modal',
  INVENTORY: 'inventory',
  KPI: 'kpis'
};

export const forecastingKeys = {
  IS_MODAL_ACTIVE: 'isModelActive',
  TOP_DOWN: 'topDown',
  MODEL: 'model',
  IGNORE_BEFORE: 'ignoreBefore',
  ZERO_LOST: 'zeroLost',
  IGNORE_LOST_DAYS: 'ignoreLostDays',
  AUTODETECT_OUTLIERS: 'autodetectOutliers',
  USE_PRICE: 'usePrice',
  CULTURE: 'culture',
  USE_PROMO: 'usePromo',
  SEASONAL_PATTERN: 'hsSeasonalityPattern',
  CLONE: 'clone',
  ADJUST_LEVEL: 'adjustLevel',
  DEMAND_SENSING: 'demandSensing',
  ALLOW_BOM: 'allowBom',
  HS_APPROVAL: 'hsApproval',
  STATUS: 'status'
};

export const settingTabs = [
  {
    label: vueI18n.tc('Main.Ui.tabItem:at'),
    component: 'ForecastTab',
    value: settingTabKeys.FORECASTING
  },
  {
    label: vueI18n.tc('Main.Ui.tabModel:at'),
    component: 'ModelTab',
    value: settingTabKeys.MODAL
  },
  {
    label: vueI18n.tc('Main.Ui.tabInventory:at'),
    component: 'InventoryTab',
    value: settingTabKeys.INVENTORY
  },
  {
    label: vueI18n.tc('Main.Ui.tabKpi:at'),
    component: 'KPITab',
    value: settingTabKeys.KPI
  }
];

export const modelKeys = {
  AUTOMATIC: 'Automatic',
  SEASONAL: 'Seasonal',
  LINEAR: 'Linear',
  CONSTANT: 'Constant',
  INFREQUENT: 'Infrequent',
  PREORDER: 'Preorder',
  AUTO_PREORDER: 'Auto-preorder',
  AUTO_TERMINATION: 'Auto-termination'
};

export const forecastRowsConfig = {
  [forecastingKeys.IS_MODAL_ACTIVE]: {
    title: vueI18n.tc('Main.Ui.lbIsModelActive'),
    items: {
      'Yes': {
        label: vueI18n.tc('Props.IsModelActive.Y')
      },
      'No': {
        label: vueI18n.tc('Props.IsModelActive.N')
      }
    }
  },
  [forecastingKeys.TOP_DOWN]: {
    title: vueI18n.tc('Main.Ui.lbTopDown'),
    items: {
      'Bottom-up': {
        label: vueI18n.tc('Props.TopDown.Bu'),
        icon: 'up_arrow'
      },
      'Top-down': {
        label: vueI18n.tc('Props.TopDown.Td'),
        icon: 'down_arrow'
      },
      'Top-down_child': {
        label: vueI18n.tc('Props.TopDown.Td2'),
        icon: 'double_down_arrow'
      }
    }
  },
  [forecastingKeys.MODEL]: {
    title: vueI18n.tc('Main.Ui.lbModel'),
    items: {
      [modelKeys.AUTOMATIC]: {
        label: vueI18n.tc('Props.Model.Auto'),
        icon: 'hand'
      },
      [modelKeys.SEASONAL]: {
        label: vueI18n.tc('Props.Model.Seasonal'),
        icon: 'hand'
      },
      [modelKeys.LINEAR]: {
        label: vueI18n.tc('Props.Model.Linear'),
        icon: 'hand'
      },
      [modelKeys.CONSTANT]: {
        label: vueI18n.tc('Props.Model.Const'),
        icon: 'hand'
      },
      [modelKeys.INFREQUENT]: {
        label: vueI18n.tc('Props.Model.Infrequent'),
        icon: 'hand'
      },
      [modelKeys.PREORDER]: {
        label: vueI18n.tc('Props.Model.Preorder'),
        icon: 'hand'
      },
      [modelKeys.AUTO_PREORDER]: {
        label: vueI18n.tc('Props.Model.Auto2'),
        icon: 'hand'
      },
      [modelKeys.AUTO_TERMINATION]: {
        label: vueI18n.tc('Props.Model.AutoDying'),
        icon: 'hand'
      }
    }
  },
  [forecastingKeys.IGNORE_BEFORE]: {
    title: vueI18n.tc('Main.Ui.lbIgnoreFrom'),
    items: true
  },
  [forecastingKeys.ZERO_LOST]: {
    title: vueI18n.tc('Main.Ui.lbZeroLost'),
    items: {
      'Yes': {
        label: vueI18n.tc('Props.ZeroLost.Y'),
        icon: 'slashed-0'
      },
      'No': {
        label: vueI18n.tc('Props.ZeroLost.N')
      }
    }
  },
  [forecastingKeys.IGNORE_LOST_DAYS]: {
    title: vueI18n.tc('Main.Ui.lbIgnoreLostDays'),
    items: {
      'Yes': {
        label: vueI18n.tc('Props.IgnoreLostDays.Y')
      },
      'No': {
        label: vueI18n.tc('Props.IgnoreLostDays.N')
      }
    }
  },
  [forecastingKeys.AUTODETECT_OUTLIERS]: {
    title: vueI18n.tc('Main.Ui.lbDetectOutliers'),
    items: {
      'Yes': {
        label: vueI18n.tc('Props.IgnoreLostDays.Y')
      },
      'No': {
        label: vueI18n.tc('Props.IgnoreLostDays.N')
      }
    }
  },
  [forecastingKeys.USE_PRICE]: {
    title: vueI18n.tc('Main.Ui.lbPriceElasticity'),
    items: {
      'Auto': {
        label: vueI18n.tc('Props.PriceElasticity.Auto')
      },
      'Yes': {
        label: vueI18n.tc('Props.PriceElasticity.Y')
      },
      'No': {
        label: vueI18n.tc('Props.PriceElasticity.N')
      }
    }
  },
  [forecastingKeys.CULTURE]: {
    title: vueI18n.tc('Main.Ui.lbCalendar'),
    items: true
  },
  [forecastingKeys.SEASONAL_PATTERN]: {
    title: vueI18n.tc('Main.Ui.lbSeasonalityPattern'),
    items: {
      'auto': {
        label: vueI18n.tc('Main.Auto')
      }
    },
    button: true
  },
  [forecastingKeys.USE_PROMO]: {
    title: vueI18n.tc('Main.Ui.lbPromotions'),
    items: {
      'Yes': {
        label: vueI18n.tc('Props.Promo.Y')
      },
      'No': {
        label: vueI18n.tc('Props.Promo.N')
      }
    },
    button: true
  },
  [forecastingKeys.CLONE]: {
    title: vueI18n.tc('Main.Ui.lbCloneModel')
  },
  [forecastingKeys.ADJUST_LEVEL]: {
    title: vueI18n.tc('Main.Ui.lbAdjustLevel'),
    items: {
      'Yes': {
        label: vueI18n.tc('Props.AdjustLevel.Y')
      },
      'No': {
        label: vueI18n.tc('Props.AdjustLevel.N')
      }
    }
  },
  [forecastingKeys.DEMAND_SENSING]: {
    title: vueI18n.tc('Main.Ui.acClearMultipleDemandSensing'),
    items: {
      'Yes': {
        label: vueI18n.tc('Props.DemandSensing.Y')
      },
      'No': {
        label: vueI18n.tc('Props.DemandSensing.N')
      }
    }
  },
  [forecastingKeys.ALLOW_BOM]: {
    title: vueI18n.tc('Main.Ui.lbAllowBom'),
    items: {
      'Yes': {
        label: vueI18n.tc('Props.Bom.Y')
      },
      'No': {
        label: vueI18n.tc('Props.Bom.N'),
        icon: 'cart'
      }
    }
  },
  [forecastingKeys.HS_APPROVAL]: {
    title: vueI18n.tc('Main.Ui.lbApprovalHorizon'),
    items: true
  },
  [forecastingKeys.STATUS]: {
    title: vueI18n.tc('Main.Ui.lbChannelCheckedState'),
    items: true
  }
};

export const useModelFromConfig = {
  wrong: {
    icon: 'red_warning',
    title: () => vueI18n.tc('Error.UnknownItem')
  },
  use_from_myself: {
    icon: 'red_warning',
    title: () => vueI18n.tc('Error.UseModelFromMyself')
  },
  cyclic_dir_fault: {
    icon: 'ouroboros',
    title: (itemCode) => vueI18n.t('Error.Loop', { 1: itemCode }),
    fill: true
  },
  cyclic: {
    icon: 'ouroboros',
    title: (itemCode) => vueI18n.t('Error.Loop', { 1: itemCode }),
    fill: true
  },
  partial_channel_match: {
    mismatch: true,
    title: () => vueI18n.tc('Error.PartialChannelMatch')
  },
  channel_mismatch: {
    mismatch: true,
    title: () => vueI18n.tc('Error.NoChannelMatch')
  }
};

export const modelCoefficientTypes = {
  MODEL: 'model',
  TOP_DOWN: 'topDown'
};
