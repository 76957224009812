<template>
  <div class="tree-filter">
    <SlAccordion
      variant="light"
      :mount-open="isFiltersOpened"
    >
      <template #trigger="{ isOpen, toggle }">
        <div
          class="tree-filter__trigger"
          @click="handleFiltersToggle(toggle, isOpen)"
        >
          <div class="body-2-md grey-80">
            {{ $t('Web.Tree.Configuration') }}
          </div>
          <icon
            data="@icons/chevron_down.svg"
            class="fill-off size-16"
            :class="{
              'rotate-180': !isOpen
            }"
          />
        </div>
      </template>
      <SlSelect
        v-if="orders.length"
        v-model="treeOrderModel"
        :options="orders"
        label="name"
        track-by="order"
        size="sm"
      />
      <SlSelect
        v-if="categoriesList.length"
        v-model="treeCategoriesModel"
        :options="categoriesList"
        track-by="value"
        label="value"
        :close-on-select="false"
        :placeholder="$t('Main.ShowCats')"
        class="categories-select"
        size="sm"
        multiple
        allow-empty
        @close="handleCategoriesChanged"
      >
        <template #selection="{ values }">
          <div class="categories-select__label">
            {{ getCategoriesLabel(values) }}
          </div>
        </template>
      </SlSelect>
      <SlCheckbox
        v-if="hasAbc"
        v-model="abcAnalysisModel"
        :label="$t('Web.Tree.GroupByABC')"
        size="sm"
        toggle
      />
      <SlCheckbox
        v-if="isStockoutOverstockVisible"
        v-model="hideStockModel"
        size="sm"
        toggle
      >
        <template #label>
          <span>
            {{ $t('Web.Tree.Hide') }}
          </span>
          <SlBadge variant="accent-green">
            {{ $t('Web.Tree.Overstock') }}
          </SlBadge>
          /
          <SlBadge variant="accent-red">
            {{ $t('Web.Tree.Shortage') }}
          </SlBadge>
        </template>
      </SlCheckbox>
      <SlCheckbox
        v-model="hideInactiveModel"
        :label="$t('Web.Tree.HideInactive')"
        size="sm"
        toggle
      />
    </SlAccordion>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { access } from '@/mixins/access';
import {
  noChannelTreeOrders,
  noLocationTreeOrders,
  treeFilterKeys,
  treeOrders
} from '@/config/demand/tree/tree.config';
import { ampli } from '@/plugins/product/ampli';

export default {
  name: 'TreeFilter',
  mixins: [access],
  data() {
    return {
      isCategoriesChanged: null
    };
  },
  computed: {
    ...mapState({
      treeMetadata: (state) => state.demand.tree.metadata,
      categoriesList: (state) => state.demand.tree.categories_list,
      isFiltersOpened: (state) => state.demand.tree.is_filters_opened
    }),
    ...mapGetters({
      hasAbc: 'project/hasAbc',
      hasLocation: 'project/hasLocation',
      hasChannel: 'project/hasChannel'
    }),
    orders() {
      if (this.hasChannel && this.hasLocation) {
        return treeOrders(this);
      }

      if (this.hasChannel) {
        return noLocationTreeOrders(this);
      }

      if (this.hasLocation) {
        return noChannelTreeOrders(this);
      }

      return [];
    },
    isStockoutOverstockVisible() {
      return this.$sl_hasAccess(this.$sl_features.salesData)
        && this.$sl_hasAccess(this.$sl_features.purchasingData);
    },
    treeOrderModel: {
      get() {
        return this.treeMetadata[treeFilterKeys.ORDER];
      },
      set(value) {
        this.handleFilterChange({
          [treeFilterKeys.ORDER]: value
        });

        ampli.treeOrderChanged({
          demandTreeOrder: value
        });
      }
    },
    treeCategoriesModel: {
      get() {
        return this.treeMetadata[treeFilterKeys.CATEGORIES];
      },
      set(value) {
        this.handleFilterChange({
          [treeFilterKeys.CATEGORIES]: value,
          needUpdate: false
        });

        this.isCategoriesChanged = true;
      }
    },
    abcAnalysisModel: {
      get() {
        return this.treeMetadata[treeFilterKeys.ABC_ANALYSIS];
      },
      set(value) {
        this.handleFilterChange({
          [treeFilterKeys.ABC_ANALYSIS]: value
        });

        ampli.abcVisibilityInTreeChanged({
          checkboxStatus: value
        });
      }
    },
    hideStockModel: {
      get() {
        return this.treeMetadata[treeFilterKeys.HIDE_STOCK];
      },
      set(value) {
        this.handleFilterChange({
          [treeFilterKeys.HIDE_STOCK]: value,
          needUpdate: false
        });

        ampli.distortionsVisibilityChanged({
          checkboxStatus: value
        });
      }
    },
    hideInactiveModel: {
      get() {
        return this.treeMetadata[treeFilterKeys.HIDE_INACTIVE];
      },
      set(value) {
        this.handleFilterChange({
          [treeFilterKeys.HIDE_INACTIVE]: value
        });

        ampli.inactiveItemsVisibilityChanged({
          checkboxStatus: value
        });
      }
    }
  },
  methods: {
    ...mapActions('demand/tree', [
      'setFilterByKey',
      'setIsFiltersOpened'
    ]),
    getCategoriesLabel(values) {
      return values.map(({ value }) => value).join(', ');
    },
    handleFilterChange({ needUpdate = true, ...data }) {
      this.setFilterByKey({
        ...data,
        needUpdate
      });
    },
    handleFiltersToggle(toggle, isOpen) {
      this.setIsFiltersOpened(!isOpen);

      toggle();
    },
    handleCategoriesChanged() {
      if (!this.isCategoriesChanged) {
        return;
      }

      this.handleFilterChange({
        [treeFilterKeys.CATEGORIES]: this.treeCategoriesModel
      });

      ampli.categoriesInTreeChanged();

      this.isCategoriesChanged = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/tree/tree-filter.scss";
</style>
