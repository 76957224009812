var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getNoteTooltipValue()),expression:"getNoteTooltipValue()"}],ref:"treeItem",class:['tree-item', {
    'color--over': _vm.isBlue(),
    'tree-item--leaf': !_vm.hasChilds(),
    'tree-item--active': _vm.isActiveItem
  }],attrs:{"data-node-index":_vm.index,"tabindex":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.handleLabelClicks($event)},"mouseup":function($event){if('button' in $event && $event.button !== 1){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();$event.stopPropagation();return _vm.handleMiddleClick($event)}}},[_vm._l((_vm.item.depth),function(depth){return _c('i',{key:depth,staticClass:"tree-item__deep-divider"})}),(_vm.hasChilds())?_c('transition-group',{staticClass:"tree-item__prepend",attrs:{"name":"fade","tag":"div"}},[(_vm.isLoading)?_c('SlLoader',{key:"loader",staticClass:"tree-item__loader",attrs:{"size":"3xs","text":false}}):_vm._e(),_c('icon',{key:"chevron",class:['size-14 fill-off tree-item__arrow', {
        'tree-item__arrow--opened': _vm.isOpen()
      }],attrs:{"data":require("@icons/chevron_right.svg")},on:{"click":function($event){$event.stopPropagation();return _vm.handleFolderToggle($event)}}})],1):_vm._e(),_c('div',{class:['tree-item__label', {
      'tree-item__label--inactive': _vm.isInactive()
    }]},[(_vm.isWarning())?_c('icon',{staticClass:"tree-item__icon fill-off size-14",attrs:{"data":require("@tree/needs-attention.svg")}}):(_vm.isChecked())?_c('icon',{staticClass:"tree-item__icon fill-off size-14",attrs:{"data":require("@tree/checked.svg")}}):_vm._e(),_vm._l((_vm.itemIcons),function(imgItem,imgIndex){return _c('icon',{key:imgItem.image + imgIndex,class:['tree-item__icon size-14', {
        'fill-off': !imgItem.fill
      }],attrs:{"data":imgItem.image}})}),_vm._v(" "+_vm._s(_vm.item.text)+" "),(_vm.isStockShown() && _vm.checkIsStockShown(_vm.item.overstock))?_c('SlBadge',{staticClass:"tree-item__badge",attrs:{"variant":"accent-green"}},[_vm._v(" "+_vm._s(_vm.item.overstock)+" ")]):_vm._e(),(_vm.isStockShown() && _vm.checkIsStockShown(_vm.item.stockout))?_c('SlBadge',{staticClass:"tree-item__badge",attrs:{"variant":"accent-red"}},[_vm._v(" "+_vm._s(_vm.item.stockout)+" ")]):_vm._e()],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }