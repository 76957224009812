<template>
  <div class="demand-table__column">
    <div
      class="demand-table__cell demand-table__cell--date"
      @click.right.prevent
    >
      {{ column.date }}
    </div>
    <div
      v-for="(header, headerIndex) in headers"
      :key="`${header.class}_${headerIndex}`"
      v-tooltip="getNoteTooltipValue(header)"
      class="demand-table__cell"
      :class="{
        'has-note': getCellNote(header)
      }"
      @click.right.stop.prevent="handleContextMenu($event, header)"
    >
      <template v-if="getCell(header)">
        <DemandTableInput
          v-if="checkIsInputCell(header)"
          :cell="getCell(header)"
          :header="header"
          :disabled="disabled"
          :clear-disabled="checkIsClearInputDisabled(header)"
          @submit="handleSubmitInput"
        />
        <DemandTableCheckbox
          v-else-if="checkIsCheckboxCell(header)"
          :cell="getCell(header)"
          :header="header"
          :disabled="disabled"
          @change="handleChangeCheckbox"
        />
        <div
          v-else
          class="demand-table__cell-text"
          :class="getCellClasses(header)"
        >
          {{ getCell(header).val }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DemandTableInput from '@/components/Demand/DemandTable/Cells/DemandTableInput.vue';
import DemandTableCheckbox from '@/components/Demand/DemandTable/Cells/DemandTableCheckbox.vue';
import { modal } from '@/mixins/modal';
import { checkboxRowKeys, rowKeys } from '@/config/demand/table.config';
import { getNoteTooltip } from '@/helpers/shared/note';

export default {
  name: 'DemandTableColumn',
  components: {
    DemandTableCheckbox,
    DemandTableInput
  },
  mixins: [modal],
  props: {
    column: {
      type: Object,
      required: true
    },
    columnIndex: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    customRowsCount: {
      type: Number,
      required: true
    }
  },
  provide() {
    return {
      toggleUpdateStatus: this.toggleUpdateStatus
    };
  },
  computed: {
    ...mapState({
      headers: (state) => state.demand.table.table?.header || [],
      canUpdate: (state) => state.lastChanges.canUpdate
    }),
    hasFinalForecastRow() {
      return this.headers.some(header => header.index === 0);
    }
  },
  mounted() {
    if (this.columnIndex === 0) {
      this.$emit('table-ready');
    }
  },
  methods: {
    ...mapActions({
      setUpdateStatus: 'lastChanges/setUpdateStatus',
      updateTableOverride: 'demand/table/updateTableOverride'
    }),
    getCell({ class: key, index, name }) {
      // base cell
      if (index === undefined) {
        return this.column[key];
      }

      // final override cell
      if (index === 0) {
        return this.column[key]?.[index];
      }

      // custom row cell
      return this.column[key]?.find(cell => cell.name === name);
    },
    getCellClasses(header) {
      const foreground = this.getCell(header)?.foreground;

      return {
        [`color--${foreground}`]: foreground
      };
    },
    getCellNote(header) {
      return this.getCell(header)?.note;
    },
    getNoteTooltipValue(header) {
      const note = this.getCellNote(header);

      if (!note) {
        return '';
      }

      return getNoteTooltip(note);
    },
    checkIsInputCell(header) {
      return this.getCell(header)?.editable && !this.checkIsCheckboxCell(header);
    },
    checkIsCheckboxCell({ class: key }) {
      return checkboxRowKeys.includes(key);
    },
    checkIsClearInputDisabled({ class: key }) {
      return key === rowKeys.REVENUE;
    },
    toggleUpdateStatus(value) {
      if (value !== this.canUpdate) {
        this.setUpdateStatus(value);
      }
    },
    handleSubmitInput(payload) {
      this.updateTableOverride({
        ...payload,
        column: this.columnIndex
      });
    },
    async handleChangeCheckbox(payload) {
      try {
        this.$set(this.column[payload.class], 'isLoading', true);

        await this.updateTableOverride({
          ...payload,
          column: this.columnIndex
        });
      } finally {
        this.$set(this.column[payload.class], 'isLoading', false);
      }
    },
    handleContextMenu(event, header) {
      this.$emit('contextmenu', {
        event,
        context: {
          ...header,
          ...this.getCell(header),
          column: this.columnIndex
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/table/demand-table-cell";

.demand-table__column {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 110px;

  background: linear-gradient($grey-10 50%, $white 0) 0 0 / 1px 60px;
  border-right: 1px solid $grey-30;
}
</style>
