<template>
  <SlCombobox
    v-tooltip="comboboxTooltip"
    :value="value"
    :options="itemCodes"
    placeholder=" "
    :internal-search="false"
    :loading="isLoading"
    :is-invalid="isRedCombobox"
    open-direction="auto-height"
    append-to-body-option-selector="clone-combobox"
    size="sm"
    :disabled="disabled"
    @search="handleSearch"
    @input="handleInput"
    @open="handleOpen"
    @close="handleClose"
  >
    <template #noOptions>
      {{ $t('Web.Forecasting.NoDataUseModelFrom') }}
    </template>
    <template
      v-if="comboboxConfig.icon"
      #append-inner
    >
      <icon
        v-tooltip="iconTooltip"
        :data="comboboxIcon"
        :class="['size-20', {
          'fill-off': !comboboxConfig.fill
        }]"
      />
    </template>
  </SlCombobox>
</template>

<script>
import { customDebounce } from '@/helpers/shared/listeners';
import { mapActions, mapState } from 'vuex';
import { useModelFromConfig } from '@/config/demand/settings.config';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'CloneCombobox',
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: Boolean
  },
  data() {
    return {
      localValue: '',
      itemCodes: [],
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      useModelFromStatus: (state) => state.demand.settings.forecast?.useModelFrom
    }),
    comboboxConfig() {
      return useModelFromConfig[this.useModelFromStatus] || {};
    },
    comboboxIcon() {
      return require(`@tree/${this.comboboxConfig.icon}.svg`);
    },
    comboboxTooltip() {
      return this.comboboxConfig.mismatch && getTooltip(this.comboboxConfig.title());
    },
    iconTooltip() {
      return getTooltip(this.comboboxConfig.title(this.value));
    },
    isRedCombobox() {
      return !!this.comboboxConfig.icon || this.comboboxConfig.mismatch;
    }
  },
  methods: {
    ...mapActions({
      fetchItemCodes: 'project/fetchItemCodes'
    }),
    searchItemCodes: customDebounce(async function(query) {
      this.itemCodes = await this.fetchItemCodes({ query });

      this.isLoading = false;
    }, 200),
    handleSearch(query) {
      if (!query) {
        return;
      }

      this.isLoading = true;

      this.searchItemCodes(query);
    },
    handleInput(value) {
      this.localValue = value;
    },
    handleOpen() {
      this.localValue = this.value;
    },
    handleClose() {
      if (this.localValue !== this.value) {
        this.$emit('input', this.localValue);
      }
    }
  }
};
</script>

<style lang="scss">
.clone-combobox {
  max-width: 200px;
}
</style>