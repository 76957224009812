import i18n from '@/plugins/vue-i18n';
import moment from 'moment';

export const initialTableGenerator = () => {
  const rows = moment.monthsShort().map((month, index) => ({
    coeff: {
      val: month
    },
    value: {
      val: 0
    },
    id: index
  }));

  rows.push({
    coeff: {
      val: i18n.t('Web.SeasonPatterns.LvlNoSales')
    },
    value: {
      val: 0
    },
    id: rows.length
  });

  return {
    name: '',
    rows,
    headers: [
      {
        key: 'coeff',
        name: i18n.t('Main.Coeff'),
        type: 'string'
      },
      {
        key: 'value',
        name: i18n.t('Main.Value'),
        type: 'float'
      }
    ]
  };
};