export const mouseLine = {
  id: 'mouseLine',
  afterEvent(chart) {
    const chartRef = chart.config._config;

    chartRef.options.plugins.mouseLine.x = false;
    chartRef.options.plugins.mouseLine.x = false;

    if (chart._active.length) {
      chartRef.options.plugins.mouseLine.x = chart._active[0].element.x;
    }
  },
  afterDatasetsDraw(chart) {
    const chartRef = chart.config._config;

    if (!chartRef.options.plugins.mouseLine) {
      return;
    }

    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    const x = chartRef.options.plugins.mouseLine.x || false;

    if (x) {
      ctx.save();
      ctx.strokeStyle = chartRef.options.plugins.mouseLine.color || 'black';
      ctx.lineWidth = chartRef.options.plugins.mouseLine.lineWidth || 1;
      ctx.beginPath();
      ctx.moveTo(chartRef.options.plugins.mouseLine.x, chartArea.bottom);
      ctx.lineTo(chartRef.options.plugins.mouseLine.x, chartArea.top);
      ctx.stroke();
      ctx.restore();
    }
  }
};