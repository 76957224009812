<template>
  <SlModal
    :id="id"
    :title="title"
    fullscreen
    @created="onCreated"
    @hide="onModalHide"
  >
    <div class="modal-content">
      <DemandChartBuilder
        :id="chartId"
        :chart-type="chartType"
        :chart-data="currentChartData"
      />
    </div>
  </SlModal>
</template>

<script>
import DemandChartBuilder from '@/components/Demand/Chart/DemandChartBuilder.vue';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { mapGetters } from 'vuex';

export default {
  name: 'ChartPreviewModal',
  components: {
    DemandChartBuilder
  },
  mixins: [modal],
  data() {
    return {
      id: modalsId.DEMAND_CHART_PREVIEW,
      title: null,
      chartType: null,
      closeCallback: null
    };
  },
  computed: {
    ...mapGetters({
      currentChartData: 'demand/chart/currentChartData'
    }),
    chartId() {
      return `${this.chartType}-modal`;
    }
  },
  methods: {
    onModalHide() {
      this.closeCallback?.();

      this.title = null;
      this.chartType = null;
      this.closeCallback = null;
    }
  }
};
</script>
