<template>
  <div class="demand-chart">
    <ChartContextMenu
      :id="contextMenuId"
      :ref="contextMenuId"
    />

    <canvas
      :id="id"
      class="chart-canvas"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import { mouseLine } from '@/plugins/chartjs/demand/mouseLine.plugin';
import { contextMenuPlugin } from '@/plugins/chartjs/demand/contextMenu.plugin';
import ChartContextMenu from '@/components/Demand/Chart/ChartContextMenu.vue';
import {
  chartTabKeys,
  chartTooltipsConfig,
  curveKeys,
  datasetsConfig
} from '@/config/demand/chart/chart.config';
import { numberToKMGT } from '@/helpers/utils/numberToKMGT';
import { checkEqualObject } from '@/helpers/utils/checkEqualObject';
import { updateChartDatasets } from '@/helpers/demand/chart';

export default {
  name: 'DemandChartBuilder',
  components: {
    ChartContextMenu
  },
  props: {
    id: {
      type: String,
      required: true
    },
    chartType: {
      type: String,
      required: true
    },
    chartData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chartInstance: null
    };
  },
  computed: {
    ...mapState({
      curvesVisibility: (state) => state.demand.chart.curves_visibility
    }),
    ...mapGetters({
      modelTypeName: 'demand/chart/modelTypeName',
      isColorblindMode: 'isColorblindMode'
    }),
    isQty() {
      return this.chartType === chartTabKeys.QTY;
    },
    contextMenuId() {
      return `${this.id}-context-menu`;
    }
  },
  watch: {
    chartData(newVal, oldVal) {
      if (checkEqualObject(newVal, oldVal)) {
        return;
      }

      this.updateChart(newVal);
    },
    isColorblindMode() {
      this.updateChart(this.chartData);
    }
  },
  mounted() {
    this.createChart(this.chartData);
  },
  destroyed() {
    this.destroyChart();
  },
  methods: {
    ...mapActions({
      updateCurveVisibility: 'demand/chart/updateCurveVisibility'
    }),
    destroyChart() {
      this.chartInstance && this.chartInstance.destroy();
    },
    createChart(chartData) {
      const ctx = document.getElementById(`${this.id}`).getContext('2d');

      this.chartInstance = new Chart(ctx, this.buildChartConfig(chartData));
    },
    updateChart(chartData) {
      updateChartDatasets(this.chartInstance, this.getChartDatasets(chartData));
      this.chartInstance.config.data.labels = chartData.labels;
      this.chartInstance.config.options = this.buildChartOptions(chartData);

      this.chartInstance.update();
    },
    reinitChart() {
      this.destroyChart();
      this.createChart(this.chartData);
    },
    resetZoom() {
      this.chartInstance.resetZoom();
    },
    toggleDatasetVisibility(chart, index) {
      const itemMeta = chart.getDatasetMeta(index);

      itemMeta.hidden = chart.isDatasetVisible(index);
    },
    legendClickHandler(_, { datasetIndex }, { chart }) {
      const itemMeta = chart.getDatasetMeta(datasetIndex);
      const meaning = itemMeta._dataset.meaning;
      const datasets = chart.config._config.data.datasets;
      const lastConfLimitIndex = datasets.findLastIndex(({ meaning }) => meaning === curveKeys.CONF_LIMIT);

      // toggle visibility for second CONF_LIMIT dataset
      if (meaning === curveKeys.CONF_LIMIT && datasetIndex !== lastConfLimitIndex) {
        this.toggleDatasetVisibility(chart, lastConfLimitIndex);
      }

      this.toggleDatasetVisibility(chart, datasetIndex);

      chart.update();

      this.updateCurveVisibility({
        meaning,
        visible: !itemMeta.hidden
      });
    },
    getFormattedNum(num) {
      return new Intl.NumberFormat('en-US').format(num);
    },
    getTooltipKey({ dataset }) {
      return dataset.tooltipKey || dataset.meaning || null;
    },
    getColor({ primary, colorblind }) {
      if (this.isColorblindMode) {
        return colorblind;
      }

      return primary;
    },
    getBgColorCallback(meaning, color) {
      if (this.chartType === chartTabKeys.QTY) {
        return ({ raw }) => raw?.isOutlier ? 'black' : color;
      }

      return () => meaning === curveKeys.SAFETY_STOCK ? color : 'white';
    },
    getDatasetConfig({ meaning, tooltipKey }) {
      const { color, legendIndex, layoutIndex, title } = datasetsConfig(this.modelTypeName)[meaning];
      const datasetColor = this.getColor(color);
      const baseCurvesConfig = {
        type: 'line',
        legendIndex,
        layoutIndex,
        label: title?.[this.chartType] || title || '',
        hidden: !this.curvesVisibility[meaning],
        tooltipKey,
        color: datasetColor,
        borderColor: datasetColor,
        borderJoinStyle: 'bevel',
        fill: meaning === curveKeys.SAFETY_STOCK ? { value: 0 } : false,
        pointBorderWidth: this.isQty ? 0.75 : 1,
        pointBorderColor: this.isQty ? 'white' : datasetColor,
        borderWidth: 2,
        pointRadius: 2,
        pointHoverRadius: 2,
        backgroundColor: this.getBgColorCallback(meaning, datasetColor)
      };
      const thinCurvesConfig = {
        ...baseCurvesConfig,
        borderWidth: 1,
        pointRadius: 1,
        pointHoverRadius: 0,
        pointBorderColor: 'transparent'
      };
      const promotionPointsConfig = {
        ...baseCurvesConfig,
        type: 'bubble',
        pointStyle: 'rectRot',
        borderWidth: 4,
        pointRadius: 4,
        pointHoverRadius: 0,
        backgroundColor: 'white',
        pointBorderColor: datasetColor,
        pointBorderWidth: 1,
        order: -1
      };
      const configByCurve = {
        [curveKeys.CONF_LIMIT]: thinCurvesConfig,
        [curveKeys.SAFETY_STOCK]: thinCurvesConfig,
        [curveKeys.PROMOTIONS]: promotionPointsConfig
      };

      return configByCurve[meaning] || baseCurvesConfig;
    },
    getChartScalesConfig({ labels }) {
      return {
        y: {
          ticks: {
            beginAtZero: true,
            callback(val) {
              return numberToKMGT(val, 1);
            }
          }
        },
        x: {
          display: true,
          type: 'linear',
          ticks: {
            autoSkipPadding: 20,
            maxRotation: 0,
            minRotation: 0,
            callback(val) {
              return labels[val] || '';
            }
          },
          grid: {
            drawOnChartArea: false
          }
        }
      };
    },
    getChartPluginsConfig(chartData) {
      const getTooltipKey = this.getTooltipKey;
      const getFormattedNum = this.getFormattedNum;

      return {
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 8,
            boxHeight: 8,
            sort(a, b, data) {
              const aOrder = data.datasets[a.datasetIndex].legendIndex;
              const bOrder = data.datasets[b.datasetIndex].legendIndex;

              return aOrder - bOrder;
            },
            filter({ datasetIndex }, { datasets }) {
              const currentMeaning = datasets[datasetIndex].meaning;

              if (currentMeaning !== curveKeys.CONF_LIMIT) {
                return true;
              }

              // show only first CONF_LIMIT legend dataset (next legendClickHandler)
              const firstDatasetIndex = datasets.findIndex(({ meaning }) => meaning === curveKeys.CONF_LIMIT);

              return datasetIndex === firstDatasetIndex;
            }
          },
          onClick: this.legendClickHandler
        },
        tooltip: {
          intersect: true,
          position: 'nearest',
          callbacks: {
            title(context) {
              const xPoint = context && context[0].raw.x;

              return chartData.labels[xPoint] || '';
            },
            label(context) {
              const rangeSeparator = ' → ';
              const { dataset, raw, parsed, formattedValue } = context;
              const key = getTooltipKey(context);
              let value = '';

              if (dataset.y !== null) {
                value = getFormattedNum(parsed.y);
              }

              if (Array.isArray(formattedValue)) {
                value = formattedValue
                  .map(num => getFormattedNum(+num))
                  .join(rangeSeparator);
              }

              if (raw?.y_from) {
                value = getFormattedNum(parsed.y - raw.y_from);
              }

              return chartTooltipsConfig[key]?.(value, this.chartType) || '';
            }
          },
          filter(context, index, items) {
            const key = getTooltipKey(context);

            if (![curveKeys.PROJECTED_INVENTORY, curveKeys.PROMOTIONS].includes(key)) {
              return true;
            }

            const firstIndex = items.findIndex(item => getTooltipKey(item) === key);

            if (firstIndex !== index) {
              return false;
            }

            const yPoints = items.reduce((acc, item) => {
              if (getTooltipKey(item) === key) {
                acc.push(item.parsed.y);
              }

              return acc;
            }, []);

            if (yPoints.length > 1) {
              context.formattedValue = [
                yPoints.at(0),
                yPoints.at(-1)
              ];
            }

            return true;
          }
        },
        mouseLine: {
          enabled: true,
          color: 'rgb(200, 204, 207)',
          lineWidth: 1
        },
        zoom: {
          pan: {
            enabled: true
          },
          zoom: {
            mode: 'xy',
            wheel: {
              enabled: true
            }
          },
          limits: {
            y: {
              min: 0,
              max: 10000000000
            },
            y2: {
              min: 0,
              max: 10000000000
            }
          }
        },
        contextMenu: {
          context: this,
          ref: this.contextMenuId
        }
      };
    },
    getChartDatasets({ datasets }) {
      if (!datasets) {
        return [];
      }

      return datasets.map(dataset => ({
        ...dataset,
        ...this.getDatasetConfig(dataset)
      }));
    },
    buildChartOptions(chartData) {
      return {
        resizeDelay: 25,
        normalized: true,
        responsive: true,
        maintainAspectRatio: false,
        devicePixelRatio: 3,
        interaction: {
          mode: 'x',
          intersect: false,
          axis: 'x'
        },
        elements: {
          line: {
            borderWidth: 1.5
          },
          point: {
            radius: 1.5
          }
        },
        plugins: this.getChartPluginsConfig(chartData),
        scales: this.getChartScalesConfig(chartData)
      };
    },
    buildChartData(chartData) {
      return {
        labels: chartData.labels,
        datasets: this.getChartDatasets(chartData)
      };
    },
    buildChartConfig(chartData) {
      return {
        type: 'line',
        options: this.buildChartOptions(chartData),
        data: this.buildChartData(chartData),
        plugins: [zoomPlugin, mouseLine, contextMenuPlugin]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/demand/chart/demand-chart';
</style>