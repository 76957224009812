<template>
  <div class="forecast-tab">
    <ForecastSettingsRow
      v-for="key in forecastingKeys"
      :key="key"
      v-bind="getSettingsRow(key)"
      @change="handleSettingsUpdate"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ForecastSettingsRow from '@/components/Demand/Settings/Forecast/ForecastSettingsRow.vue';
import { forecastRowsConfig, forecastingKeys } from '@/config/demand/settings.config';
import { routeNames } from '@/config/router/router.config';
import { needAttentionKeys, treeItemStates } from '@/config/demand/tree/tree.config';
import { projectRedirect } from '@/helpers/router';
import { ampli } from '@/plugins/product/ampli';

export default {
  name: 'ForecastTab',
  components: {
    ForecastSettingsRow
  },
  data() {
    return {
      forecastingKeys,
      ignoreBeforeInheritValue: 4294967295,
      inheritValue: 'Inherit'
    };
  },
  computed: {
    ...mapState({
      seasonPatternsModel: (state) => state.demand.seasonPattern.seasonal_patterns_model
    }),
    ...mapGetters({
      nodeState: 'demand/tree/activeNodeState',
      nodeMultipleState: 'demand/tree/activeNodeMultipleState',
      activeNodeId: 'demand/tree/activeNodeId',
      settings: 'demand/settings/forecastSettings',
      holidays: 'demand/settings/holidayOptions',
      actualDates: 'project/actualDates'
    }),
    rowButtonConfig() {
      return {
        [forecastingKeys.SEASONAL_PATTERN]: {
          icon: 'pencil',
          callback: this.openSeasonalityPatterns
        },
        [forecastingKeys.USE_PROMO]: {
          icon: 'monitor',
          callback: this.openPromotions
        }
      };
    },
    topDownOptions() {
      const { inheritedMassTopDown } = this.settings[forecastingKeys.TOP_DOWN] || {};
      const { items } = forecastRowsConfig[forecastingKeys.TOP_DOWN] || {};

      return [
        {
          label: inheritedMassTopDown === 'none'
            ? this.$t('Props.Inherit2', {
              1: items[this.settings[forecastingKeys.TOP_DOWN]?.baseVal]?.label
            })
            : this.$t(`Props.TopDown.${inheritedMassTopDown}`),
          value: 'Inherit'
        },
        ...this.getOptionsFromItems(items)
      ];
    },
    ignoreBeforeOptions() {
      const baseVal = this.settings[forecastingKeys.IGNORE_BEFORE]?.baseVal - 1;
      const periodIndex = baseVal >= 0 ? baseVal : 0;

      return this.actualDates.reduce((acc, date, index) => {
        acc.push({
          label: date,
          value: index,
          icon: 'stopwatch'
        });

        return acc;
      }, [{
        label: this.$t('Props.Inherit2', {
          1: this.actualDates[periodIndex]
        }),
        value: this.inheritValue
      }]);
    },
    holidayOptions() {
      const baseVal = this.settings[forecastingKeys.CULTURE]?.baseVal;

      return [
        {
          label: this.$t('Props.Inherit2', {
            1: baseVal === 'None'
              ? this.$t('Props.NoCulture')
              : baseVal
          }),
          value: 'Inherit'
        },
        {
          label: this.$t('Props.NoCulture'),
          value: 'None'
        },
        ...this.holidays
      ];
    },
    seasonalPatternOptions() {
      const { id, val } = this.settings[forecastingKeys.SEASONAL_PATTERN]?.baseVal || {};
      const inheritItem = this.seasonPatternsModel.find(item => item.id === id && item.state === val);

      return this.seasonPatternsModel.reduce((acc, item) => {
        if (item.state !== 'inherit') {
          acc.push({
            label: item.name,
            value: item.id ?? item.state
          });
        }

        return acc;
      }, [{
        label: this.$t('Props.Inherit2', {
          1: inheritItem?.name
        }),
        value: 'inherit'
      }]);
    },
    approvalOptions() {
      const { baseVal } = this.settings[forecastingKeys.HS_APPROVAL] || {};

      return [{
        label: this.$t('Props.Inherit2', {
          1: baseVal
        }),
        value: this.inheritValue
      }];
    },
    statusOptions() {
      return [
        {
          label: '',
          value: ''
        },
        {
          label: this.$t('Props.CheckedState.Normal'),
          value: treeItemStates.NORMAL
        },
        {
          label: this.$t('Props.CheckedState.Attention'),
          value: treeItemStates.WARNING,
          icon: 'needs-attention'
        },
        {
          label: this.$t('Props.CheckedState.Checked'),
          value: treeItemStates.CHECKED,
          icon: 'checked'
        }
      ].slice(this.nodeMultipleState ? 0 : 1);
    },
    optionsByKeyMap() {
      return {
        [forecastingKeys.TOP_DOWN]: this.topDownOptions,
        [forecastingKeys.IGNORE_BEFORE]: this.ignoreBeforeOptions,
        [forecastingKeys.CULTURE]: this.holidayOptions,
        [forecastingKeys.SEASONAL_PATTERN]: this.seasonalPatternOptions,
        [forecastingKeys.HS_APPROVAL]: this.approvalOptions,
        [forecastingKeys.STATUS]: this.statusOptions
      };
    }
  },
  methods: {
    ...mapActions({
      updateForecastSettings: 'demand/settings/updateForecastSettings',
      toggleNodeState: 'demand/tree/toggleNodeState'
    }),
    getInheritOption(items, key) {
      return {
        label: this.$t('Props.Inherit2', {
          1: items[this.settings[key]?.baseVal]?.label || this.settings[key]?.baseVal || ''
        }),
        value: this.inheritValue
      };
    },
    getOptionsFromItems(items) {
      return Object.entries(items).reduce((acc, [key, value]) => {
        acc.push({
          value: key,
          label: value?.label,
          icon: value?.icon
        });

        return acc;
      }, []);
    },
    getRowOptions(items, key) {
      if (!items) {
        return [];
      }

      const getUniqueOptions = this.optionsByKeyMap[key];

      if (getUniqueOptions) {
        return getUniqueOptions;
      }

      const options = this.getOptionsFromItems(items);

      options.unshift(this.getInheritOption(items, key));

      return options;
    },
    getRowValue(key) {
      const { baseVal, val, id, inherited } = this.settings[key] || {};

      if (key === forecastingKeys.SEASONAL_PATTERN) {
        return id ?? val;
      }

      if (key === forecastingKeys.CLONE) {
        return inherited && baseVal
          ? this.$t('Props.Inherit2', {
            1: baseVal
          })
          : val;
      }

      if (key === forecastingKeys.STATUS) {
        if (this.nodeMultipleState) {
          return '';
        }

        return needAttentionKeys.indexOf(this.nodeState) !== -1
          ? treeItemStates.WARNING
          : this.nodeState;
      }

      return inherited ? this.inheritValue : val;
    },
    getSettingsRow(key) {
      const { title, items, button } = forecastRowsConfig[key] || {};

      return {
        rowKey: key,
        name: title || '',
        value: this.getRowValue(key),
        options: this.getRowOptions(items, key),
        ...(button && { button: this.rowButtonConfig[key] })
      };
    },
    openSeasonalityPatterns() {
      projectRedirect({
        name: routeNames.SEASONALITY_PATTERNS
      });
    },
    openPromotions() {
      projectRedirect({
        name: routeNames.PROMOTIONS
      });
    },
    handleSettingsUpdate({ key, value }) {
      if (key === forecastingKeys.STATUS && value) {
        return this.toggleNodeState({
          nodeId: this.activeNodeId,
          state: value
        });
      }

      let settingValue = value;

      if (key === forecastingKeys.IGNORE_BEFORE) {
        settingValue = value === this.inheritValue ? this.ignoreBeforeInheritValue : value;
      }

      if (key === forecastingKeys.HS_APPROVAL) {
        settingValue = value.value ?? value;
      }

      if ([forecastingKeys.HS_APPROVAL, forecastingKeys.CLONE].includes(key)) {
        this.$set(this.settings[key], 'val', settingValue);
      }

      this.updateForecastSettings({
        [key]: settingValue
      });

      ampli.forecastingParametersOverridden({
        forecastingParameterName: forecastRowsConfig[key].title
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/settings/tabs/forecast-tab";
</style>