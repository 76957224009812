<template>
  <SubPageWrapper
    width="full"
    class="demand-page"
    no-padding
    relative
    data-shortcut-tree
  >
    <NoteModal />
    <SubstituteItem />
    <RemoveFromImport />

    <template #loader>
      <SlOverlay :show="isPageInitializing" />
    </template>

    <DemandToolbar />
    <DemandGrid v-if="!isPageInitializing" />
  </SubPageWrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NoteModal from '@/components/Modals/Shared/NoteModal.vue';
import SubstituteItem from '@/components/Modals/SubstitutionRules/SubstituteItem.vue';
import RemoveFromImport from '@/components/Modals/SubstitutionRules/RemoveFromImport.vue';
import DemandToolbar from '@/components/Demand/Toolbar/DemandToolbar.vue';
import DemandGrid from '@/components/Demand/DemandGrid.vue';
import { modal } from '@/mixins/modal';
import { treeNodeActions } from '@/mixins/demand/tree/treeNodeActions.mixin';
import { shortcut } from '@/mixins/shortcut';
import { sendRequests } from '@/helpers/lastChange/sendRequests.js';
import { projectRedirect } from '@/helpers/router';

export default {
  name: 'Demand',
  components: {
    SubstituteItem,
    RemoveFromImport,
    NoteModal,
    DemandToolbar,
    DemandGrid
  },
  mixins: [modal, shortcut, treeNodeActions],
  data() {
    return {
      isPageInitializing: false
    };
  },
  computed: {
    ...mapGetters({
      treeActivePathId: 'demand/tree/treeActivePathId'
    })
  },
  watch: {
    treeActivePathId(id) {
      projectRedirect({
        params: {
          id: id || undefined
        }
      });
    }
  },
  async beforeMount() {
    try {
      this.isPageInitializing = true;

      this.resetNodeNotes();
      await this.updateNodeIdParameter();

      await sendRequests(this.$sl_routeName);
    } finally {
      this.$store.dispatch('demand/tree/setIsLinkRedirect', false);

      this.isPageInitializing = false;
    }
  },
  methods: {
    ...mapActions({
      updateNodePath: 'demand/tree/updateNodePath',
      resetNodeNotes: 'note/resetNodeNotes'
    }),
    async updateNodeIdParameter() {
      const routeId = this.$route.params.id;

      if (routeId) {
        await this.updateNodePath(routeId);
      }

      projectRedirect({
        params: {
          id: this.treeActivePathId || undefined
        }
      });
    }
  }
};
</script>
