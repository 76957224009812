var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"model-tab"},[_c('SaveAsPatternModal'),_c('SlOverlay',{attrs:{"show":_vm.initializing}}),(_vm.isTabAvailable)?_c('div',{staticClass:"model-tab__content"},[_vm._l((_vm.modelsConfig),function(model){return [(model.isAvailable)?_c('div',{key:model.type,staticClass:"model-tab__table"},[(model.title)?_c('div',{staticClass:"model-tab__title"},[_vm._v(" "+_vm._s(model.title)+" "),(model.isSeasonal)?_c('SlButton',{attrs:{"variant":"secondary","color":"grey","disabled":!_vm.$sl_tabEditable},on:{"click":_vm.handleSaveAsPattern}},[_vm._v(" "+_vm._s(_vm.$t('Main.Ui.btMakePattern'))+" ")]):_vm._e()],1):_vm._e(),_c('SlTable',{attrs:{"id":model.type,"header-unique-key":"key","header-name-key":"name","unique-key":"id","rows":model.table.body,"headers":model.table.header,"max-height":800,"col-resize":false,"col-initial-width":130,"initial-col-sizes":['calc(100% - 130px)', '130px']},scopedSlots:_vm._u([{key:"coefficient",fn:function(ref){
var row = ref.row;
var cellValue = ref.cellValue;
return [(cellValue)?_c('SlTableInput',{attrs:{"value":_vm.getInputValue(cellValue),"text-value":_vm.getTextValue(cellValue),"foreground":cellValue.foreground,"disabled":!cellValue.editable,"input-regexp":_vm.regExp.negativeFloatInput,"submit-regexp":_vm.regExp.negativeFloatSubmit},on:{"submit":function (val) { return _vm.handleUpdateModel({
                cellValue: cellValue,
                type: model.type,
                rowId: row.id,
                val: val
              }); }}}):_vm._e()]}}],null,true)})],1):_vm._e()]})],2):_c('NotAvailableLevel')],1)}
var staticRenderFns = []

export { render, staticRenderFns }