<template>
  <SlDropdown
    :triggers="[]"
    :overflow-padding="5"
    placement="bottom-start"
    content-class="sl-tree-search-dropdown"
    no-auto-focus
    @apply-show="handleResultsShown"
    @hide="handleResultsHide"
  >
    <template #target="{ open, close }">
      <SlSearchInput
        v-model="searchModel"
        :placeholder="$t('Main.SearchStub')"
        :loading="isSearching"
        @click.native="handleOpenResults(open)"
        @blur="handleCloseResults(close)"
        @submit="(query) => handleSearch({ query, open })"
      />
    </template>
    <template
      v-if="foundNodes"
      #content
    >
      <SlTreeSearchOption
        v-for="node in foundNodes"
        :key="node.id"
        :item="node"
        ignore-first-path
        @select="handleSelectFoundNode(node.id)"
      >
        {{ node.id }}
      </SlTreeSearchOption>
      <SlNoResultsOption v-if="!foundNodes.length" />
      <div
        v-if="isPortionLoading"
        class="sl-tree-search__loader"
      >
        <SlLoader
          size="xs"
          inline
        />
      </div>
    </template>
  </SlDropdown>
</template>

<script>
import { mapActions } from 'vuex';
import { scrollEndListener } from '@/helpers/shared/listeners';

export default {
  name: 'DemandTreeSearch',
  data() {
    return {
      searchModel: '',
      isSearching: false,
      foundNodes: [],
      // used to compare diff result count to stop lazy load (server not send all results count)
      resultsCount: 0,
      loadPortion: 0,
      scrollLoadOffset: 250,
      clearFoundNodesTimeout: 300,
      activeScroll: false,
      isPortionLoading: false
    };
  },
  watch: {
    searchModel(val) {
      if (!val) {
        setTimeout(() => {
          this.foundNodes = [];
        }, this.clearFoundNodesTimeout);
      }
    }
  },
  methods: {
    ...mapActions({
      searchTreeNodes: 'demand/tree/searchTreeNodes',
      loadFoundNodes: 'demand/tree/loadFoundNodes',
      fetchFoundNodeTree: 'demand/tree/fetchFoundNodeTree'
    }),
    clearSearchState() {
      const menuNode = this.getMenuNode();

      if (menuNode) {
        menuNode.scrollTop = 0;
      }

      this.resultsCount = 0;
      this.loadPortion = 0;
      this.isPortionLoading = false;
    },
    async handleSearch({ query, open }) {
      if (!query) {
        return;
      }

      this.clearSearchState();

      try {
        this.isSearching = true;

        this.foundNodes = await this.searchTreeNodes(query);

        open();
      } finally {
        this.isSearching = false;
      }
    },
    handleOpenResults(open) {
      if (!this.foundNodes?.length && !this.searchModel) {
        return;
      }

      open();
    },
    handleCloseResults(close) {
      setTimeout(close, this.clearFoundNodesTimeout);
    },
    handleSelectFoundNode(id) {
      this.searchModel = '';

      this.fetchFoundNodeTree(id);
    },
    getMenuNode() {
      return document.querySelector('.sl-tree-search-dropdown .v-popper__inner');
    },
    handleResultsShown() {
      const menuNode = this.getMenuNode();

      menuNode.addEventListener('scroll', this.lazyLoadResults);
      scrollEndListener(menuNode, () => this.activeScroll = false, 300);
    },
    handleResultsHide() {
      const menuNode = this.getMenuNode();

      if (menuNode) {
        menuNode.removeEventListener('scroll', this.lazyLoadResults);
      }
    },
    async lazyLoadResults(e) {
      if (this.resultsCount === this.foundNodes?.length) {
        return e.preventDefault();
      }

      if (!this.activeScroll) {
        this.activeScroll = true;
      }

      const scrollHeight = e.target.scrollHeight;
      const alreadyScrolled = e.target.scrollTop + e.target.offsetHeight;

      if (scrollHeight - alreadyScrolled >= this.scrollLoadOffset) {
        return;
      }

      this.loadPortion += 1;
      this.resultsCount = this.foundNodes?.length || 0;

      try {
        this.isPortionLoading = true;

        const results = await this.loadFoundNodes({
          query: this.searchModel,
          portion: this.loadPortion
        });

        if (results) {
          this.foundNodes?.push(...results);
        }
      } finally {
        this.isPortionLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sl-tree-search__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
}
</style>