<template>
  <SlContextMenu
    :id="id"
    ref="contextMenu"
    :options="options"
    @option-click="handleOptionClick"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { access } from '@/mixins/access';
import {
  demandChartOptions,
  demandChartActionsMap
} from '@/config/shared/contextMenu.config';
import { rowKeys } from '@/config/demand/table.config';
import { forecastingKeys } from '@/config/demand/settings.config';

export default {
  name: 'ChartContextMenu',
  mixins: [access],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      periodDate: null,
      periodIndex: null
    };
  },
  computed: {
    ...mapGetters({
      pKnown: 'project/pKnown'
    }),
    options() {
      const options = demandChartOptions(this, this.periodDate);
      const isActual = this.periodDate && this.periodIndex <= this.pKnown;

      return options.filter(option => {
        const isCanvas = option.group === 'canvas';

        if (!this.$sl_tabEditable) {
          return isCanvas;
        }

        return isActual || isCanvas;
      });
    }
  },
  methods: {
    ...mapActions({
      updateTableOverride: 'demand/table/updateTableOverride',
      updateForecastSettings: 'demand/settings/updateForecastSettings'
    }),
    show({ event, context }) {
      this.setPeriod(context);

      this.$refs.contextMenu.show({ event, context });
    },
    setPeriod({ chart, dataX }) {
      const labels = chart.data.labels;

      this.periodIndex = Math.round(dataX);
      this.periodDate = labels[this.periodIndex];
    },
    zoomOut({ chart }) {
      chart.resetZoom();
    },
    ignoreActualSalesBefore() {
      this.updateTableOverride({
        column: this.periodIndex,
        class: rowKeys.OUTLIER,
        value: true
      });
    },
    ignoreTrendBefore() {
      this.updateForecastSettings({
        [forecastingKeys.IGNORE_BEFORE]: this.periodIndex + 1
      });
    },
    handleOptionClick({ key, context }) {
      const action = demandChartActionsMap[key];

      this[action] && this[action](context);
    }
  }
};
</script>