<template>
  <div
    :class="['demand-table-checkbox', {
      'demand-table-checkbox--disabled': isDisabled
    }]"
  >
    <SlCheckbox
      :value="cell.val"
      :disabled="isDisabled"
      :indeterminate="cell.isMixed"
      :readonly="cell.isMixed"
      @mousedown.native="handleCheckboxClick"
      @change="handleChange"
    />
    <icon
      v-if="cell.isOverridden"
      v-tooltip="getTooltip($t('Main.DemandTable.ToolTips.ClearOverrides'))"
      data="@icons/backward.svg"
      class="demand-table-checkbox__icon size-16 fill-off"
      @click="handleClearOverride"
    />
  </div>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'DemandTableCheckbox',
  mixins: [modal],
  props: {
    cell: {
      type: Object,
      required: true
    },
    header: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  computed: {
    isDisabled() {
      return this.disabled || !this.cell.editable || this.cell.isLoading;
    }
  },
  methods: {
    getTooltip,
    handleChange(value) {
      this.$set(this.cell, 'val', value);

      this.$emit('change', {
        ...this.header,
        value
      });
    },
    handleCheckboxClick() {
      if (!this.cell.isMixed) {
        return;
      }

      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        icon: 'style_warning_double',
        title: this.$t('Common.Warning'),
        text: this.$t('Main.OvCheckboxQuestion'),
        confirmText: this.$t('Common.Ok'),
        confirmCallback: () => this.handleChange(false)
      });
    },
    handleClearOverride() {
      if (!this.cell.isMixed || !this.cell.isOverridden) {
        return this.handleChange(null);
      }

      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        icon: 'style_warning_double',
        title: this.$t('Web.Modals.ActionConfirm.TitleClearOverrides'),
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonClear'),
        confirmCallback: () => this.handleChange(null)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.demand-table-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: 100%;
  width: 100%;

  &__icon {
    cursor: pointer;
  }

  &.demand-table-checkbox--disabled {
    pointer-events: none;
  }
}
</style>