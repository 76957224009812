<template>
  <SlTableInput
    v-tooltip="getErrorTooltip(cell.tooltip)"
    v-bind="inputProps"
    @focus="handleFocus"
    @input="handleInput"
    @submit="handleSubmit"
  />
</template>

<script>
import regExp from '@/helpers/utils/regExp';
import { getErrorTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'DemandTableInput',
  props: {
    cell: {
      type: Object,
      required: true
    },
    header: {
      type: Object,
      required: true
    },
    disabled: Boolean,
    clearDisabled: Boolean
  },
  data() {
    return {
      initialValue: null,
      formulaMaxLength: 200
    };
  },
  inject: [
    'toggleUpdateStatus'
  ],
  computed: {
    isCustomRow() {
      return this.header.index !== undefined;
    },
    inputProps() {
      const defaultProps = {
        value: this.cell.edit ?? this.cell.val,
        disabled: this.disabled || !this.cell.editable,
        textValue: this.cell.val,
        foreground: this.cell.foreground
      };

      if (this.isCustomRow) {
        return {
          ...defaultProps,
          type: 'text',
          maxLength: this.formulaMaxLength,
          isInvalid: !!this.cell.tooltip
        };
      }

      return {
        ...defaultProps,
        inputRegexp: regExp.floatInput,
        submitRegexp: regExp.floatSubmit
      };
    }
  },
  methods: {
    getErrorTooltip,
    handleFocus() {
      this.initialValue = this.cell.edit;

      this.toggleUpdateStatus(false);
    },
    handleInput(value) {
      this.$set(this.cell, 'val', value);
    },
    async handleSubmit(value) {
      if (!value && this.clearDisabled) {
        return this.$set(this.cell, 'val', this.initialValue);
      }

      this.$set(this.cell, 'foreground', 'auto');
      this.$set(this.cell, 'val', value);

      this.$emit('submit', {
        ...this.header,
        value
      });

      this.initialValue = null;
      this.toggleUpdateStatus(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.sl-table-input-wrapper {
  text-align: right;

  ::v-deep .sl-table-input {
    text-align: right;
  }
}
</style>