var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tree-filter"},[_c('SlAccordion',{attrs:{"variant":"light","mount-open":_vm.isFiltersOpened},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('div',{staticClass:"tree-filter__trigger",on:{"click":function($event){return _vm.handleFiltersToggle(toggle, isOpen)}}},[_c('div',{staticClass:"body-2-md grey-80"},[_vm._v(" "+_vm._s(_vm.$t('Web.Tree.Configuration'))+" ")]),_c('icon',{staticClass:"fill-off size-16",class:{
            'rotate-180': !isOpen
          },attrs:{"data":require("@icons/chevron_down.svg")}})],1)]}}])},[(_vm.orders.length)?_c('SlSelect',{attrs:{"options":_vm.orders,"label":"name","track-by":"order","size":"sm"},model:{value:(_vm.treeOrderModel),callback:function ($$v) {_vm.treeOrderModel=$$v},expression:"treeOrderModel"}}):_vm._e(),(_vm.categoriesList.length)?_c('SlSelect',{staticClass:"categories-select",attrs:{"options":_vm.categoriesList,"track-by":"value","label":"value","close-on-select":false,"placeholder":_vm.$t('Main.ShowCats'),"size":"sm","multiple":"","allow-empty":""},on:{"close":_vm.handleCategoriesChanged},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var values = ref.values;
return [_c('div',{staticClass:"categories-select__label"},[_vm._v(" "+_vm._s(_vm.getCategoriesLabel(values))+" ")])]}}],null,false,217389986),model:{value:(_vm.treeCategoriesModel),callback:function ($$v) {_vm.treeCategoriesModel=$$v},expression:"treeCategoriesModel"}}):_vm._e(),(_vm.hasAbc)?_c('SlCheckbox',{attrs:{"label":_vm.$t('Web.Tree.GroupByABC'),"size":"sm","toggle":""},model:{value:(_vm.abcAnalysisModel),callback:function ($$v) {_vm.abcAnalysisModel=$$v},expression:"abcAnalysisModel"}}):_vm._e(),(_vm.isStockoutOverstockVisible)?_c('SlCheckbox',{attrs:{"size":"sm","toggle":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('Web.Tree.Hide'))+" ")]),_c('SlBadge',{attrs:{"variant":"accent-green"}},[_vm._v(" "+_vm._s(_vm.$t('Web.Tree.Overstock'))+" ")]),_vm._v(" / "),_c('SlBadge',{attrs:{"variant":"accent-red"}},[_vm._v(" "+_vm._s(_vm.$t('Web.Tree.Shortage'))+" ")])]},proxy:true}],null,false,2607056694),model:{value:(_vm.hideStockModel),callback:function ($$v) {_vm.hideStockModel=$$v},expression:"hideStockModel"}}):_vm._e(),_c('SlCheckbox',{attrs:{"label":_vm.$t('Web.Tree.HideInactive'),"size":"sm","toggle":""},model:{value:(_vm.hideInactiveModel),callback:function ($$v) {_vm.hideInactiveModel=$$v},expression:"hideInactiveModel"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }