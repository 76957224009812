<template>
  <div class="chart-widget">
    <ChartPreviewModal />

    <div class="chart-widget__toolbar">
      <SlTabList
        v-if="chartTabs.length"
        v-model="tabModel"
        :tabs="chartTabs"
        horizontal
      >
        <template #after-list>
          <div class="chart-widget__actions">
            <SlButton
              v-tooltip="getTooltip('Reset zoom')"
              variant="tertiary"
              color="grey"
              size="md"
              icon
              @click="handleResetZoom"
            >
              <icon
                data="@icons/zoom-reset.svg"
                class="size-16 fill-off"
              />
            </SlButton>
            <SlButton
              v-tooltip="getTooltip('Enter full screen')"
              variant="tertiary"
              color="grey"
              size="md"
              icon
              @click="handlePreviewChart"
            >
              <icon
                data="@icons/fullscreen.svg"
                class="size-16 fill-off"
              />
            </SlButton>
          </div>
        </template>
      </SlTabList>
    </div>
    <div class="chart-widget__content">
      <div class="chart-widget__title">
        <div class="chart-widget__title-label">
          {{ activeNoteLabel }}
        </div>
      </div>
      <SlOverlay :show="isLoading" />
      <SlTabContent
        v-for="tab in chartTabs"
        :key="tab.value"
        :value="tab.value"
        :tab-value="tabModel"
      >
        <DemandChartBuilder
          v-if="!isLoading && currentChartData"
          :id="tab.value"
          ref="chart"
          :chart-type="tabModel"
          :chart-data="currentChartData"
        />
      </SlTabContent>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ChartPreviewModal from '@/components/Modals/Demand/ChartPreviewModal.vue';
import DemandChartBuilder from '@/components/Demand/Chart/DemandChartBuilder.vue';
import { access } from '@/mixins/access';
import { modal } from '@/mixins/modal';
import { chartTabKeys, chartTabOptions } from '@/config/demand/chart/chart.config';
import modalsId from '@/config/shared/modalsId.config';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'DemandChartsWidget',
  components: {
    ChartPreviewModal,
    DemandChartBuilder
  },
  mixins: [access, modal],
  computed: {
    ...mapState({
      activeNoteLabel: (state) => state.demand.tree.active_node?.text || '',
      chartType: (state) => state.demand.chart.chart_type,
      isLoading: (state) => state.demand.chart.is_loading
    }),
    ...mapGetters({
      currentChartData: 'demand/chart/currentChartData'
    }),
    chartTabs() {
      if (!this.$sl_hasAccess(this.$sl_features.salesData)) {
        return chartTabOptions.filter(({ value }) => value !== chartTabKeys.REVENUE );
      }

      return chartTabOptions;
    },
    tabModel: {
      get() {
        return this.chartType;
      },
      set(value) {
        this.setChartType(value);
      }
    }
  },
  methods: {
    ...mapActions({
      setChartType: 'demand/chart/setChartType'
    }),
    getTooltip,
    getChart() {
      return this.$refs.chart[0] ?? this.$refs.chart;
    },
    reinitChart() {
      const chart = this.getChart();

      chart?.reinitChart?.();
    },
    handleResetZoom() {
      const chart = this.getChart();

      chart?.resetZoom?.();
    },
    handlePreviewChart() {
      this.showModal(modalsId.DEMAND_CHART_PREVIEW, {
        title: this.activeNoteLabel,
        chartType: this.chartType,
        closeCallback: this.reinitChart
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/demand/chart/chart-widget';
</style>