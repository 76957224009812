<template>
  <div
    ref="treeItem"
    v-tooltip="getNoteTooltipValue()"
    :class="['tree-item', {
      'color--over': isBlue(),
      'tree-item--leaf': !hasChilds(),
      'tree-item--active': isActiveItem
    }]"
    :data-node-index="index"
    tabindex="0"
    @click.stop="handleLabelClicks"
    @click.middle.exact.prevent.stop="handleMiddleClick"
  >
    <i
      v-for="depth in item.depth"
      :key="depth"
      class="tree-item__deep-divider"
    />

    <transition-group
      v-if="hasChilds()"
      name="fade"
      tag="div"
      class="tree-item__prepend"
    >
      <SlLoader
        v-if="isLoading"
        key="loader"
        size="3xs"
        :text="false"
        class="tree-item__loader"
      />
      <icon
        key="chevron"
        :class="['size-14 fill-off tree-item__arrow', {
          'tree-item__arrow--opened': isOpen()
        }]"
        data="@icons/chevron_right.svg"
        @click.stop="handleFolderToggle"
      />
    </transition-group>
    <div
      :class="['tree-item__label', {
        'tree-item__label--inactive': isInactive()
      }]"
    >
      <icon
        v-if="isWarning()"
        data="@tree/needs-attention.svg"
        class="tree-item__icon fill-off size-14"
      />
      <icon
        v-else-if="isChecked()"
        data="@tree/checked.svg"
        class="tree-item__icon fill-off size-14"
      />
      <icon
        v-for="(imgItem, imgIndex) in itemIcons"
        :key="imgItem.image + imgIndex"
        :data="imgItem.image"
        :class="['tree-item__icon size-14', {
          'fill-off': !imgItem.fill
        }]"
      />
      {{ item.text }}
      <SlBadge
        v-if="isStockShown() && checkIsStockShown(item.overstock)"
        variant="accent-green"
        class="tree-item__badge"
      >
        {{ item.overstock }}
      </SlBadge>
      <SlBadge
        v-if="isStockShown() && checkIsStockShown(item.stockout)"
        variant="accent-red"
        class="tree-item__badge"
      >
        {{ item.stockout }}
      </SlBadge>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { access } from '@/mixins/access';
import { treeItemStates } from '@/config/demand/tree/tree.config';
import { nodeFlags, treeFgs } from '@/config/shared/fgs.config';
import { getNoteTooltip, getNoteTooltipTemplate } from '@/helpers/shared/note';
import { openLink } from '@/helpers/shared/webAPI';
import { getNodeUrl } from '@/helpers/demand/tree';

export default {
  name: 'TreeVirtualItem',
  mixins: [access],
  props: {
    item: {
      type: Object,
      required: true
    },
    nextItem: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    itemIcons: {
      type: Array,
      required: true
    },
    hideStock: Boolean,
    isActiveItem: Boolean,
    isLoading: Boolean
  },
  data() {
    return {
      nodeFlags,
      treeFgs,
      treeItemStates,
      clickTimer: null,
      clickCount: 0,
      clickDelay: 350
    };
  },
  computed: {
    ...mapGetters({
      getNoteByEntity: 'note/getNoteByEntity'
    })
  },
  methods: {
    ...mapActions({
      openTreeChild: 'demand/tree/openTreeChild',
      hideTreeChild: 'demand/tree/hideTreeChild',
      setActiveNode: 'demand/tree/setActiveNode',
      fetchNotice: 'note/fetchNotice'
    }),
    isStockShown() {
      return !this.hideStock
        && (this.$sl_hasAccess(this.$sl_features.salesData)
          && this.$sl_hasAccess(this.$sl_features.purchasingData))
        && (this.checkIsStockShown(this.item.overstock)
          || this.checkIsStockShown(this.item.stockout));
    },
    getNoteTooltipValue() {
      if (!this.hasNote()) {
        return;
      }

      const note = this.getNoteByEntity(this.item.uniId);

      if (note) {
        return getNoteTooltip(note);
      }

      return {
        theme: 'dark',
        content: this.loadItemNote,
        html: true
      };
    },
    hasChilds() {
      return this.item.hasChilds;
    },
    hasNote() {
      return this.item.nodeFlags & nodeFlags.HAS_NOTE;
    },
    isOpen() {
      return this.hasChilds() && this.item.depth < this.nextItem.depth;
    },
    isBlue() {
      return !this.hasChilds() && this.item.nodeFlags & nodeFlags.TIMEPOINTS_OVERRIDEN;
    },
    isWarning() {
      return this.item.state !== treeItemStates.CHECKED && this.item.state !== treeItemStates.NORMAL;
    },
    isChecked() {
      return this.item.state === treeItemStates.CHECKED;
    },
    isInactive() {
      return this.item.fgs & treeFgs.INACTIVE_NODE;
    },
    checkIsStockShown(price) {
      if (!price) {
        return '';
      }

      return +price !== 0 && price !== 'nan';
    },
    handleLabelClicks() {
      if (!this.isActiveItem) {
        this.handleSelectItem();
      }

      this.clickCount++;

      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0;
        }, this.clickDelay);
      } else {
        clearTimeout(this.clickTimer);
        this.handleFolderToggle();
        this.clickCount = 0;
      }
    },
    async handleFolderToggle() {
      if (!this.hasChilds()) {
        return;
      }

      if (this.isOpen()) {
        return this.hideTreeChild({
          nodeId: this.item.nodeId,
          index: this.index
        });
      }

      await this.openTreeChild({
        nodeId: this.item.nodeId,
        index: this.index
      });
    },
    handleSelectItem() {
      this.setActiveNode({
        node: this.item,
        clearSearch: true
      });
    },
    async handleMiddleClick(e) {
      e.preventDefault();

      openLink(getNodeUrl(this.item.nodeId), true);
    },
    async loadItemNote() {
      const note = await this.fetchNotice(this.item.uniId);

      return getNoteTooltipTemplate(note);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/tree/tree-virtual-item.scss";
</style>
