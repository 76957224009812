import noTrack from '@tree/no_truck.svg';
import downArrow from '@tree/down_arrow.svg';
import doubleDownArrow from '@tree/double_down_arrow.svg';
import upArrow from '@tree/up_arrow.svg';
import stopwatch from '@tree/stopwatch.svg';
import slashed0 from '@tree/slashed-0.svg';
import hand from '@tree/hand.svg';
import yellowHand from '@tree/yellow_hand.svg';
import calendar from '@tree/calendar.svg';
import cart from '@tree/cart.svg';
import multiplier from '@tree/multiplier.svg';
import redWarning from '@tree/red_warning.svg';
import ouroboros from '@tree/ouroboros.svg';
import ouroborosGrey from '@tree/ouroboros_grey.svg';
import note from '@icons/note.svg';
import outlier from '@tree/chart.svg';
import approved from '@tree/approve.svg';
import { treeFgs } from '../../shared/fgs.config';
import { nodeFlags } from '@/config/shared/fgs.config';

export default [
  { 
    image: approved,
    class: 'default',
    type: 'nodeFlags',
    flag: nodeFlags.HAS_APPROVED_PREDICTION
  },
  { 
    image: noTrack,
    class: 'default',
    type: 'fgs',
    flag: treeFgs.ORDER_SET
  },
  { 
    image: noTrack,
    class: 'grey-icon',
    type: 'fgs',
    flag: treeFgs.ORDER_INHERITED
  },
  { 
    image: downArrow,
    class: 'default',
    type: 'fgs',
    flag: treeFgs.TD_TOPDOWN
  },
  { 
    image: downArrow,
    class: 'grey-icon',
    type: 'fgs',
    flag: treeFgs.TD_INH_TOPDOWN
  },
  {
    image: doubleDownArrow,
    class: 'default',
    type: 'fgs',
    flag: treeFgs.TD_TOPDOWN_CHILDREN
  },
  {
    image: upArrow,
    class: 'default',
    type: 'fgs',
    flag: treeFgs.TD_BOTTOMUP
  },
  {
    image: upArrow,
    class: 'grey-icon',
    type: 'fgs',
    flag: treeFgs.TD_INH_BOTTOMUP
  },
  {
    image: stopwatch,
    class: 'default',
    type: 'fgs',
    flag: treeFgs.IGNOREDATA_SET
  },
  {
    image: stopwatch,
    class: 'grey-icon',
    type: 'fgs',
    flag: treeFgs.IGNOREDATA_INHERITED
  },
  {
    image: hand,
    class: 'default',
    type: 'fgs',
    flag: treeFgs.MODEL_SET
  },
  {
    image: hand,
    class: 'grey-icon',
    type: 'fgs',
    flag: treeFgs.MODEL_INHERITED
  },
  {
    image: yellowHand,
    type: 'fgs',
    flag: treeFgs.MODEL_IMPORTED
  },
  {
    image: multiplier,
    class: 'default',
    type: 'fgs',
    flag: treeFgs.MULTIPLIER_SET
  },
  {
    image: multiplier,
    class: 'grey-icon',
    type: 'fgs',
    flag: treeFgs.MULTIPLIER_INHERITED
  },
  {
    image: slashed0,
    class: 'default',
    type: 'fgs',
    flag: treeFgs.ZEROLOST_YES_SET
  },
  {
    image: slashed0,
    class: 'grey-icon',
    type: 'fgs',
    flag: treeFgs.ZEROLOST_YES_INHERITED
  },
  {
    image: calendar,
    class: 'default',
    type: 'fgs',
    flag: treeFgs.CULTURE_SET
  },
  {
    image: calendar,
    class: 'grey-icon',
    type: 'fgs',
    flag: treeFgs.CULTURE_INHERITED
  },
  {
    image: redWarning,
    class: 'default',
    type: 'fgs',
    flag: treeFgs.USEMODEL_WRONG
  },
  {
    image: ouroboros,
    class: 'default',
    type: 'fgs',
    flag: treeFgs.USEMODEL_CYCLIC_FAULTY,
    fill: true
  },
  {
    image: ouroborosGrey,
    class: 'grey-icon',
    type: 'fgs',
    flag: treeFgs.USEMODEL_CYCLIC_INHERITED,
    fill: true
  },
  {
    image: cart,
    class: 'default',
    type: 'fgs',
    flag: treeFgs.BOM_NO
  },
  {
    image: cart,
    class: 'grey-icon',
    type: 'fgs',
    flag: treeFgs.BOM_NO_INHERITED
  },
  {
    image: outlier,
    class: 'default',
    type: 'nodeFlags',
    flag: nodeFlags.HAS_CUSTOM_OUTLIER
  },
  {
    image: note,
    class: 'default',
    type: 'nodeFlags',
    flag: nodeFlags.HAS_NOTE
  }
];
