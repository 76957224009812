<template>
  <div
    :class="['tree', {
      'loading': isFetching
    }]"
  >
    <TreeContextMenu ref="contextMenu" />
    <RecycleScroller
      v-slot="{ item, index }"
      :key="scrollerKey"
      ref="treeScroller"
      :items="treeItems"
      :item-size="treeItemSize"
      key-field="nodeId"
      class="tree-scroller"
    >
      <TreeVirtualItem
        :item="item"
        :index="index"
        :next-item="treeItems[index + 1] || {}"
        :hide-stock="wantHideDistortions"
        :item-icons="getItemIcons(item)"
        :is-active-item="checkIsActive(item)"
        :is-loading="item.nodeId === loadingNodeId"
        @click.right.native.prevent.stop="handleContextMenu($event, item)"
        @keyup.native.prevent.stop="handleKeyboardNavigation({
          event: $event,
          nodeId: item.nodeId,
          index
        })"
      />
    </RecycleScroller>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import TreeVirtualItem from '@/components/Demand/Tree/TreeVirtualItem.vue';
import TreeContextMenu from '@/components/Demand/Tree/TreeContextMenu.vue';
import { contextMenuFgs } from '@/config/shared/fgs.config';
import icons from '@/config/demand/tree/iconsByFlagKey.config';
import { ROOT_NODE_ID } from '@/config/demand/tree/tree.config';
import { keyCodes } from '@/config/utils/statusCodes.config';
import { checkEqualObject } from '@/helpers/utils/checkEqualObject';

export default {
  name: 'Tree',
  components: {
    TreeContextMenu,
    TreeVirtualItem
  },
  data() {
    return {
      icons,
      contextMenuFgs,
      treeItemSize: 26,
      scrollerKey: false,
      navKeyCodes: [keyCodes.arrowDown, keyCodes.arrowUp, keyCodes.arrowRight, keyCodes.arrowLeft]
    };
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.demand.tree.is_fetching,
      activeNode: (state) => state.demand.tree.active_node,
      treeItems: (state) => state.demand.tree.tree || [],
      wantHideDistortions: (state) => state.demand.tree.metadata.wantHideDistortions,
      categories: (state) => state.demand.tree.metadata.categories,
      indexScrollTo: (state) => state.demand.tree.index_scroll_to,
      loadingNodeId: (state) => state.demand.tree.loading_node_id
    }),
    ...mapGetters({
      activeNodeId: 'demand/tree/activeNodeId',
      getActionFgs: 'demand/tree/getActionFgs'
    })
  },
  watch: {
    indexScrollTo(index) {
      this.scrollToActiveNode(index);
    },
    categories: {
      handler(newVal, oldVal) {
        if (checkEqualObject(newVal, oldVal)) {
          return;
        }

        this.scrollerKey = true;

        this.$nextTick(() => {
          this.scrollerKey = false;
        });
      },
      deep: true
    }
  },
  mounted() {
    setTimeout(() => this.scrollToActiveNode(this.indexScrollTo));

    document.addEventListener('keydown', this.handleKeyboardNavigation);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyboardNavigation);
  },
  methods: {
    ...mapActions({
      setActiveNode: 'demand/tree/setActiveNode',
      openTreeChild: 'demand/tree/openTreeChild',
      hideTreeChild: 'demand/tree/hideTreeChild'
    }),
    checkIsActive(item) {
      if (!this.activeNode) {
        return item.nodeId === ROOT_NODE_ID;
      }

      return item.nodeId === this.activeNode?.nodeId;
    },
    getItemIcons(item) {
      return icons.filter(set => +item[set.type] & set.flag);
    },
    scrollToActiveNode(index) {
      if (index === null || !this.$refs.treeScroller) {
        return;
      }

      const start = this.$refs.treeScroller.$_startIndex;
      const end = this.$refs.treeScroller.$_endIndex;
      const clientHeight = this.$refs.treeScroller.$el.clientHeight;

      const visibleItemsCount = Math.floor(clientHeight / this.treeItemSize);
      const scrollerVisibleGap = end - start - visibleItemsCount;
      const halfGap = Math.floor(scrollerVisibleGap / 2);

      const isVisible = index > start - halfGap && index < end - halfGap;

      if (!isVisible) {
        const itemOffset = index * this.treeItemSize;
        const scrollTo = itemOffset - Math.round(clientHeight / 2 - this.treeItemSize / 2);

        this.$refs.treeScroller.scrollToPosition(scrollTo);
      }
    },
    scrollIntoView(index) {
      const el = document.querySelector(`.tree-item[data-node-index="${index}"]`);

      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    },
    handleKeyboardNavigation(event) {
      if (!this.navKeyCodes.includes(event.keyCode)) {
        return;
      }

      const activeNode = document.querySelector('.tree-item--active');

      if (!activeNode) {
        return;
      }

      const index = parseInt(activeNode.getAttribute('data-node-index'), 10);

      if (isNaN(index)) {
        return;
      }

      const currentNode = this.treeItems[index];
      const nextNode = this.treeItems[index + 1];
      const prevNode = this.treeItems[index - 1];
      const isOpen = currentNode.hasChilds && currentNode.depth < nextNode?.depth;

      if (event.keyCode === keyCodes.arrowDown) {
        if (!nextNode) {
          return;
        }

        event.preventDefault();
        this.scrollIntoView(index + 1);

        return this.setActiveNode({
          node: nextNode,
          clearSearch: true,
          needScroll: true
        });
      }

      if (event.keyCode === keyCodes.arrowUp) {
        if (!prevNode) {
          return;
        }

        event.preventDefault();
        this.scrollIntoView(index - 1);

        return this.setActiveNode({
          node: prevNode,
          clearSearch: true,
          needScroll: true
        });
      }

      if (event.keyCode === keyCodes.arrowRight) {
        if (!currentNode.hasChilds || isOpen) {
          return;
        }

        return this.openTreeChild({
          nodeId: currentNode.nodeId,
          index
        });
      }

      if (event.keyCode === keyCodes.arrowLeft) {
        if (!currentNode.hasChilds || !isOpen) {
          return;
        }

        this.hideTreeChild({
          nodeId: currentNode.nodeId,
          index
        });
      }
    },
    handleContextMenu(event, context) {
      this.$refs.contextMenu.show({ event, context });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/tree/tree.scss";
</style>
